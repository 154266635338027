import { useState } from 'react'
import MasterHeader from '../masterHeader';
import locationService from '../../../services/locationData.service';
import { Link } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import { useNavigate } from 'react-router-dom';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import { read, utils } from 'xlsx';

function PincodeImport() {
    const [searchQuery, setSearchQuery] = useState('');
    interface StateCityCombination {
        stateTableId: string;
        countryTableId: string;
      }
      interface City {
        stateTableId: string;
        countryTableId: string;
        cityTableId : string;
        // Add other properties if needed
      }
    const [selectedRecords, setSelectedRecords] = useState();
    const [allPincodes, setallPincodes] = useState([]);
    const [allCity, setallCity] = useState<City[]>([]);
    const [allState, setallState] = useState<StateCityCombination[]>([]);
    const createdBy =  localStorage.getItem('userId');
    const updatedBy =  localStorage.getItem('userId');
    const mailRoomId =  localStorage.getItem('mailRoomId');

    //Table Start
    interface selectRowType {
        mode: any;
        clickToSelect: boolean; // Fixed typo: clickToSelect instead of clickToSlect
        style: any;
        onSelect: any;
    }
    const selectRow: selectRowType = {
        mode: "radio",
        clickToSelect: true,
        style: { backgroundColor: '#c8e6c9' },
        onSelect: (row: any) => {
            setSelectedRecords(row.id);
        }
    }
    interface Article {
        options: any,
        currSizePerPage: any,
        onSizePerPageChange: any,
    }
    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }: Article) => (
        <div className="btn-group" role="group">
            {
                options.map((option: any) => {
                    const isSelect = currSizePerPage === `${option.page}`;
                    return (
                        <button
                            key={option.text}
                            type="button"
                            onClick={() => onSizePerPageChange(option.page)}
                            className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
                        >
                            {option.text}
                        </button>
                    );
                })
            }
        </div>
    );

    interface optionsType {
        sizePerPageRenderer: any
    }
    const options: optionsType = {
        sizePerPageRenderer
    };

    const pagination = paginationFactory(options);
    const columns: ColumnDescription<selectRowType>[] = [
        { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => { return rowIndex + 1; }, text: 'id', align: 'left', headerClasses: 'left-aligned-header' },
        { dataField: 'zipCode', text: 'Pincode', align: 'left', headerClasses: 'left-aligned-header' },
        { dataField: 'cityTableId', text: 'City', align: 'left', headerClasses: 'left-aligned-header' },
        { dataField: 'stateTableId', text: 'State', align: 'left', headerClasses: 'left-aligned-header' },
        { dataField: 'countryTableId', text: 'Country', align: 'left', headerClasses: 'left-aligned-header' },
    ];
    //Table End


    const navigate = useNavigate();
   
    const filteredData = Array.isArray(allPincodes) ? allPincodes.filter((selectedRecords) => {
        const values = Object.values(selectedRecords);
        return values.some((value: unknown): value is string =>
            typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
        );

    }) : [];
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger'
        },
        buttonsStyling: false
    })

    
  //------------excel import --------------
  
  
  const handleImport = (event: any) => {   
    if (!event.target.files) return;
    const files = event.target.files;
    if (files.length) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (event:any) => {
            const wb = read(event.target.result);
            const sheets = wb.SheetNames;
            if (sheets.length) { 
              const rows: any  = utils.sheet_to_json(wb.Sheets[sheets[0]]);
            //   var newArr :any = [...rows];
              const  awbObjects = rows.map((data: any, index :any) => (
               {
                eid: index,
                zipCode: (data[Object.keys(data)[0]]).toString(),
                cityTableId: data[Object.keys(data)[1]],
                stateTableId : data[Object.keys(data)[2]],
                countryTableId : data[Object.keys(data)[3]],
                mailRoomId : mailRoomId,
                createdBy : createdBy,
                updatedBy : updatedBy ,
             }
             ));
         
                setallPincodes(awbObjects);
                let uniqueState  = Array.from(
                    new Set(awbObjects.map((pincode:any) => JSON.stringify({ stateTableId: pincode.stateTableId, countryTableId: pincode.countryTableId })))
                ).map((jsonString:any) => JSON.parse(jsonString));
                setallState(uniqueState)
                // console.log("uniqueState"+JSON.stringify(uniqueState));

                let uniquecity = Array.from(
                    new Set(awbObjects.map((pincode:any) => JSON.stringify({ cityTableId: pincode.cityTableId,stateTableId: pincode.stateTableId, countryTableId: pincode.countryTableId })))
                ).map((jsonString:any) => JSON.parse(jsonString));
                setallCity(uniquecity)
               // console.log("uniquecity"+JSON.stringify(uniquecity));
    
            }

          // Convert the pin code data into a set of unique state and city combinations
          


        }
        reader.readAsArrayBuffer(file);

    }
}
  //-------------excel import end here ---------

    const handleSubmit = () => {
        if(allPincodes.length > 0) {
        locationService.importPinCode(allPincodes, allCity, allState).then(
            (data)=>{
              if(data){
                Swal.fire({
                  title: "Pincode Import in Progress",
                  icon: 'success',
                  text: "Pincode has Imported Please Wait....",
              }).then(function() {
              navigate("../pincodelist")
              })  
              }
            }
          )
        }
    }

    return (
        <>

            <MasterHeader />
            <div style={{ margin: "0px" }}>
                <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
                    <div className="container col-11">
                        <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}>Pincode List </p></div>
                        <div className='col-12 inline'>
                        <div className='col-6'>
                                <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange= {handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/> <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
                                </div>
                        <div className='col-6' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "end", alignItems: "center" }}>
                            <input
                                className='searchbar col-3'
                                type='text'
                                value={searchQuery}
                                placeholder='Search Here ...'
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                        </div>
                        <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%', textAlign: "center" }}
                            keyField='id'
                            striped
                            hover
                            condensed
                            // data={allServiceProvider}
                            data={filteredData}
                            columns={columns}
                            pagination={pagination}
                            selectRow={selectRow}
                            filter={filterFactory()}
                            filterPosition="top"
                        />

                    </div>
                    <div className='row col-12'>
                            <div className='row col-4 d-flex justify-content-center'>
                            <Link to="../uploads/excelformat/Pincodemaster.xlsx"  className="btn2 col-6"  target='_blank' > <i className='fa fa-download'></i> Download Template</Link>
                        </div>
                <div className='row col-8 d-flex justify-content-end'>
                    {/* <div className='row col-12 d-flex justify-content-center'> */}
                        <Link to="" className="btn2 col-2"  onClick={handleSubmit} > <i className="fa fa-plus" aria-hidden="true"></i>  Upload</Link>
                         <Link className="btn2 col-2" to="../dashboard"><i className="fa fa-sign-out"></i>  Close</Link>

                    </div>
                    </div>
                </div>
            </div>

        </>
    );
}
export default PincodeImport
