import React, { useState, useCallback } from "react";
import { BarChart, Bar, Cell, LabelList, ResponsiveContainer } from "recharts";
import '../dashboard.css';

export default function InwardStatusBarChart(props: any) {
  const [activeIndex, setActiveIndex] = useState(0);
  const data = props.dataforBarChart
    .filter((d: any) => d.value !== null && d.value !== 0 && d.value !== "") // Filter out null, 0, and blank values
    .map((d: any) => ({
      name: d.name,
      value: d.value,
    }));
  const activeItem = data ? data[activeIndex] : {};
  const handleClick = useCallback(
    (entry: any, index: number) => {
      setActiveIndex(index);
    },
    []
  );

  return (
    <div className="chart-container">
      <p className="chart-title">Inward Status of {props.todaysDate}</p>
      <ResponsiveContainer width="100%" height={180}>
        <BarChart data={data} >
          <Bar dataKey="value" onClick={handleClick}>
            {data.map((_entry: any, index: number) => (
              <Cell
                cursor="pointer"
                fill={index === activeIndex ? "rgb(94, 83, 83)" : "#db1c29"}
                key={`cell-${index}`}
              />
            ))}
            <LabelList
              dataKey="name"
              position="top"
              style={{ fontSize: 12, fill: "#333" }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      {(activeItem && activeItem.value !== null && activeItem.value !== 0 && activeItem.value !== "") && (
        <p className="active-value">
          Active Item: <span>{activeItem.name}</span>
          <span className="value">Value: {activeItem.value}</span>
        </p>
      )}
    </div>
  );
}
