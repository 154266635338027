import React, { useEffect, useState } from 'react'
import Outcontainerheader from './Outcontainerheader';
import MasterDataService from '../../services/masterData.service';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import OutwardLogService from '../../services/outwardlog.service';
import Swal from 'sweetalert2';
import moment from 'moment';
const customStyles = {
  option: (provided: any, { isFocused, isSelected }: any) => ({
    ...provided,
    padding: 5,
    zIndex: 1,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
  control: (prev: any) => ({
    ...prev,
    padding: 0,
    width: "100%"

  }),
  menu: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    zIndex: 100,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
}

const Outwordlog = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [batchNo, setbatchNo] = useState("");
  const [outwardDate, setoutwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'))
  const [outwardTime, setoutwardTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm'))

  const [modeName, setmodeName] = useState('')
  const [employeeName, setemployeeName] = useState('')
  const [employeeTableId, setemployeeTableId] = useState(0);
  const [employeeID, setemployeeID] = useState('')
  const [costCenter, setcostCenter] = useState(0)
  const [allCostCenter, setallCostCenter] = useState([]);
  const [costCenterLabel, setcostCenterLabel] = useState('');
  const [refNo, setrefNo] = useState('')
  const [noOfShipment, setnoOfShipment] = useState(1)
  const [transferType, settransferType] = useState('Drop');
  const [pickupStaff, setpickupStaff] = useState('')
  const [pickupStaffId, setpickupStaffId] = useState(0)
  const [remarks, setremarks] = useState('')
  const [department, setdepartment] = useState('');
  const [allEmployee, setAllEmployee] = useState([]);
  const [emailId, setemailId] = useState("");
  const [lockUnlock, setlockUnlock] = useState(false);
  const [modeId, setmodeId] = useState(0);
  const [shipmentmode, setshipmentmode] = useState([])
  const [alldeliveryBoyName, setalldeliveryBoyName] = useState([]);

  // get pre values by id 
  const getoutwardLogById = async () => {
    try {
      const data = await OutwardLogService.getoutwardLogById(state);
      if (data) {
        setbatchNo(data.batchNo)
        setmodeId(data.modeName);
        setoutwardDate(data.outwardDate);
        setcostCenter(data.costCenter);
        setrefNo(data.refNo);
        setnoOfShipment(data.noOfShipment);
        settransferType(data.transferType);
        setpickupStaff(data.pickupStaff);
        setremarks(data.remarks);
        setemployeeName(data.employeeName);
        setemployeeTableId(data.employeeTableId)


      } 
    } catch (error) {
      // Handle errors from the API call
      console.error(error);
    }
  };

  // get employee option
  const getAllEmployee = async () => {
    await MasterDataService.getEmployeeList().then(
      (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.employeeName,
            'emailId': d.emailId,
            'departmentName': d.departmentName,
            'costcenter': d.costCenter,
            'costcenterName': d.costCenterName,
            'employeeID' : d.employeeId,

          }))
          setAllEmployee(options);
          if(state === null){
            // if(data[0]){
            //   setemployeeID(data[0]['employeeId'])
            //   setemployeeName(data[0]['employeeName'])
            //   setemployeeTableId(data[0]['id'])
            //   setemailId(data[0]['emailId'])
            //   setdepartment(data[0]['departmentName'])
            //   setcostCenterLabel(data[0]['costCenterName'])
            // }
          }

        }

      }
    );
  }

  const  setEmployeeDetails = () => {
    let employeeDetail = allEmployee.find((item: any) => {
      return item['value'] === employeeTableId
    });
    if (employeeDetail !== undefined) {     
    setemployeeID(employeeDetail['employeeID'])
    setemployeeName(employeeDetail['label'])
    setemailId(employeeDetail['emailId'])
    setdepartment(employeeDetail['departmentName'])
    setcostCenterLabel(employeeDetail['costcenterName'])
    }
  }

  const setmodeDetails = () => {
    let modesNames = shipmentmode.find((item: any) => {
      return item['value'] === modeId
    });
    if (modesNames !== undefined) {
      setmodeName(modesNames['label'])
    }
  }
  useEffect(()=>{
    setEmployeeDetails() 
  },[employeeTableId, allEmployee])

  useEffect(()=>{
    setmodeDetails() 
  },[modeId, shipmentmode])
  
  useEffect(() => {
    getAllEmployee();      
  getShipmentMode();  
  getCostCenter();  
  getpickupStaff();
    if(state) {
      getoutwardLogById()
    } 
  }, [])
  
  const employeeChange = async (e: any) => {
    setemployeeTableId(e.value)
    setemployeeName(e.label)
    setemailId(e.emailId)
    setdepartment(e.departmentName)
    setcostCenter(e.costcenter)
    setcostCenterLabel(e.costcenterName);
    setemployeeID(e.employeeID);

  }
  // post data 
  const handleSubmit = () => {
    if((modeId !== 0 ) && (employeeTableId !==0) ){
    OutwardLogService.outwardlogCreate(modeId, outwardDate, costCenter, refNo, noOfShipment, transferType, pickupStaff, remarks, employeeName, employeeTableId, employeeID).then(
      (data) => {
        if (data) {
          if (!lockUnlock) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: "Outward Details Created Successfully",
              showConfirmButton: false,
              timer: 1500,
              customClass: {
                title: 'swal-fire-alert', // Add your custom CSS class here
              },
            }).then(function () {
              navigate('../outwardloglist');
            });
          } 
        }
      }
    )
    } else {
      let message = "";
  if((modeId === 0 ) && (employeeTableId ===0) ){
    message = "Please Select Employee and Shipment Mode"
   } else if((modeId === 0 ) ){
    message = "Please Enter Shipment Mode"
   } else  if(employeeTableId === 0 ){
    message = "Please Select Employee"
   } 
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: message,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          title: 'swal-fire-alert', // Add your custom CSS class here
        },
      })
    }
  }
  const HandleEdit = () => {
    OutwardLogService.outwardlogUpdate(modeId, outwardDate, costCenter, refNo, noOfShipment, transferType, pickupStaff, remarks, employeeName, employeeTableId, employeeID, state).then(
      (data) => {
        if (data ) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: "Batch No :- " + data.batchNo + " Updated Successfully",
            showConfirmButton: false,
            timer: 1500,
            customClass: {
              title: 'swal-fire-alert', // Add your custom CSS class here
            },
          }).then(function () {
            navigate('../outwardloglist');
          });
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  }
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (state !== null)

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          OutwardLogService.deleteoutwardLog(state).then(
            (data) => {
              if (data) {
                navigate('../outwardloglist');
              }
            }
          ).catch((error) => {
            console.log(error);
            // console.log("server responding");

          })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Outward Log has been deleted",
          }).then(function () {
            navigate('/outwardloglist');
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Outward Log is safe :)",
            }).then(function () {
              navigate('../outwardloglist');
            }
            )
        }
      })
  }
  // get shipment mode option 
  const getShipmentMode = () => {
    MasterDataService.GetshipmentMode().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.modeName
          }))
          await setshipmentmode(options)
          if (state === null) {
            // if (data[0]) {
            //   await setmodeId(data[0]['id'])
            //   await setmodeName(data[0]['modeName'])
            // }
          }
        }
      }
    );
  }
  const handleModeChange = (e: any) => {
    setmodeId(e.value);
    setmodeName(e.label)
  }
  const getCostCenter = async () => {
    await MasterDataService.getCostCenter().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.costCenter
          }))
          await setallCostCenter(options)
          if (state === null) {
            // await setcostCenter(data[0]['id'])
            // setcostCenterLabel(data[0]['costCenter'])
          } else {

          }
        }
      }
    )
  }
useEffect(()=>{
  console.log("cost center", costCenter);
  let costCenters = allCostCenter.find((item: any) => {
    return item['value'] === costCenter
  });
  if (costCenters !== undefined) {
    setcostCenterLabel(costCenters['label'])
  }
},[allCostCenter, costCenter])

  const handleCostCenter = (e: any) => {
    setcostCenter(e.value)
    setcostCenterLabel(e.label)
  }
  const getpickupStaff = async () => {
    await MasterDataService.GetdeliveryBoy().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.deliveryBoyName
          }))
          await setalldeliveryBoyName(options)
          if (state === null) {
            // await setpickupStaffId(data[0]['id'])
            // setpickupStaff(data[0]['deliveryBoyName'])
          } 
        }
      }
    )
  }
useEffect(()=>{
  let deliveryBoyNames = alldeliveryBoyName.find((item: any) => {
    return item['label'] === pickupStaff
  });
  if (deliveryBoyNames !== undefined) {
    setpickupStaffId(deliveryBoyNames['value'])
  }
},[alldeliveryBoyName, pickupStaff])

  const handlepickupStaff = (e: any) => {
    setpickupStaffId(e.value)
    setpickupStaff(e.label)
  }
  return (
    <>
      {/* <Header /> */}
      <Outcontainerheader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11" style={{paddingLeft : '0px'}}>
            <div className="themeColor" style={{ width: '100%' }} ><p style={{ marginLeft: "10px" }}> Outward Log</p></div>
            <div className=' inline' >

              <div className='col-6'>              

                <div className=' col-12 inline'>
                  <label className='col-4'> Employee Name</label>
                  <div className='col-8 select' style={{paddingLeft: '0px', paddingRight: '0px'}}>
                  <Select
                    name="employee"
                    onChange={employeeChange}
                    options={allEmployee}
                    styles={customStyles}
                    value={{ value: employeeName, label: employeeName }}
                  />
                  </div>
                </div>


                <div className='col-12 inline'>
                  <label className='col-4'> Cost center </label>
                  <div className='col-8 select' style={{paddingLeft: '0px', paddingRight: '0px'}}>
                  <Select
                    name="costCenter"
                    onChange={handleCostCenter}
                    options={allCostCenter}
                    value={{ value: costCenter, label: costCenterLabel }}
                    styles={customStyles}
                  />
                  </div>
                </div>
                <div className='col-12 inline' >
                  <label className='col-4'>Transfer Type</label>
                  <div className='col-4 inline'>
                    <label className='col-6 inline' >
                      <input
                                type='radio'
                                className='col-5'
                                name='radio'
                                onChange={() => settransferType('Drop')}
                                checked={transferType === 'Drop'}
                              /> Drop </label>

                             <label className='col-6 inline' > <input
                                type='radio'
                                className='col-5'
                                name='radio'
                                onChange={() => settransferType('Pickup')}
                                checked={transferType === 'Pickup'}
                              /> Pickup </label>
                        </div>
                        
                        <div className='col-4'>
                          <label className='col-7'> Quantity </label>
                          <input type='text' className='col-5' value={noOfShipment} name='noOfShipment' onChange={(e:any )=> { if (/^\d*$/.test(e.target.value)) {setnoOfShipment(e.target.value)}}}  />
                        </div>
                  <div className='col-8 inline' style={{ display: 'flex', alignItems: 'center' }}>
                
                      </div>
                    </div>


                    <div className='col-12 inline'>
                      <label className='col-4'> Pickup Staff </label>
                      <div className='col-8 select' style={{paddingLeft: '0px', paddingRight: '0px'}}>
                      <Select
                        name="deliveryboy"
                        onChange={handlepickupStaff}
                        options={alldeliveryBoyName}
                        value={{ value: pickupStaffId, label: pickupStaff }}
                        styles={customStyles}
                        isDisabled={(transferType === "Drop") ? true : false}
                      />
                      </div>
                    </div>
                    <div className='col-12 inline'>
                      <label className='col-4'> Mode</label>
                      <div className='col-8 select' style={{paddingLeft: '0px', paddingRight: '0px'}}>
                      <Select
                        name="modeId"
                        onChange={handleModeChange}
                        options={shipmentmode}
                        styles={customStyles}
                        value={{ value: modeId, label: modeName }}
                      />
                      </div>
                    </div>

                  </div>

                  <div className='col-6'>
                    <div className='col-12'  >
                      <label className='col-4'> BatchNo</label>
                      <input type='text' className='col-8' value={batchNo} name='batchNo' onChange={e => setbatchNo(e.target.value)} readOnly />

                    </div>

                    <div className=' col-12'>
                      <label className='col-4'> Date&Time</label>
                      <input type='date' className='col-5' value={outwardDate} name='outwardDate' onChange={e => setoutwardDate(e.target.value)} />
                      <input className='col-3' type='time' value={outwardTime} name='outwardTime' onChange={e => setoutwardTime(e.target.value)} />

                    </div>                   

                    <div className='col-12 inline'>
                      <label className='col-4' style={{ fontWeight: '500' }}> Employee Name: </label>
                      <label className='col-8 '>{employeeName} </label>
                    </div>
                    <div className='col-12'>
                      <label className='col-4' style={{ fontWeight: '500' }}> Email Id: </label>
                      <label className='col-8 '>{emailId} </label>
                    </div>
                    <div className='col-12'>
                      <label className='col-4' style={{ fontWeight: '500' }}> Department: </label>
                      <label className='col-8'>{department} </label>
                    </div>
                    <div className=' col-12'>
                      <label className='col-4' > Remark </label>
                      <input type='text' className='col-8' value={remarks} name='remarks' onChange={e => setremarks(e.target.value)} />
                    </div>
                    <div className=' col-12'>
                      <label className='col-4'> Reference No </label>
                      <input type='text' className='col-8' value={refNo} name='refNo' onChange={e => setrefNo(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='btn d-flex justify-content-center'>
              {(state === null) ? <>  <button className="btn2 col-2 col-sm-1" type="button" onClick={handleSubmit}><i className="fa fa-save"></i> Save</button> </> : <>
                <button className="btn2 col-2 col-sm-1" onClick={HandleEdit} ><i className="fa fa-edit"></i> Update </button>
                <button className="btn2 col-2 col-sm-1" onClick={HandleDelete}> <i className="fa fa-trash"></i> Delete</button></>}
              <button className="btn2 col-2 col-sm-1" > <i className="fa fa-close" ></i> Clear</button>
              <Link className="btn2 col-2 col-sm-1" to="/outwardloglist" type='button'>  <i className="fa fa-sign-out"></i> Close </Link>

            </div>
          </div>
        </>
        )
}

        export default Outwordlog