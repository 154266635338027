import { useEffect, useState } from 'react';
import '../outward/Outwarddetail.css';
import Outcontainerheader from './Outcontainerheader';
import MasterDataService from '../../services/masterData.service';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Swal from 'sweetalert2';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment';
import OutwardDetailService from '../../services/outwarddetail.sercice';
import AccountService from '../../services/accountData.service';
import OutwardLogService from '../../services/outwardlog.service';

const customStyles = {
  option: (provided: any, { isFocused, isSelected }: any) => ({
    ...provided,
    padding: 5,
    zIndex: 1,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
  control: (prev: any) => ({
    ...prev,
    padding: 0,
    width: "100%"

  }),
  menu: (base: any, { isFocused, isSelected }: any) => ({
    ...base,
    zIndex: 100,
    background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
  }),
}


function Outwarddetailentry() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [branch, setBranch] = useState((localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : 0);
  const [shipmentmode, setshipmentmode] = useState([])
  const [modeName, setmodeName] = useState('Please Select Mode');
  const [multiplyFactor, setmultiplyFactor] = useState(5000);
  const [modeId, setmodeId] = useState(0);
  const [serviceProvider, setserviceProvider] = useState('');
  const [serviceProviderId, setserviceProviderId] = useState(0);
  const [serviceProviderList, setserviceProviderList] = useState([]);
  const [allEmployee, setAllEmployee] = useState([]);
  const [employeeId, setemployeeId] = useState('');
  const [employeeName, setEmployeeName] = useState('Please Select Employee/ Consignor');
  const [entryDoneQty, setEntryDoneQty] = useState<any | null>(0)
  const [costCenter, setcostCenter] = useState(0);
  const [allCostCenter, setallCostCenter] = useState([]);
  const [ratesContract, setRatesContract] = useState<any | []>(null);
  const [costCenterLabel, setcostCenterLabel] = useState('Select a Cost Center');
  const [deliveryCheck, setdeliveryCheck] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [departmentName, setdepartmentName] = useState('');



  const [outwardDate, setoutwardDate] = useState((moment(new Date())).format('YYYY-MM-DD'));
  const [outwardTime, setoutwardTime] = useState((moment(new Date()).utcOffset('+0530')).format('HH:mm'));
  // const [outwardTime, setoutwardTime] = useState('17:29');



  // const [consignor, setConsignor] = useState("")
  // const [consignorName, setConsignorName] = useState("")
  // const [consignorZipCode, setConsignorZipCode] = useState("")
  const [allSelections, setallSelections] = useState([])

  const [allBranch, setallBranch] = useState([]);
  const [consigneeBranchId, setconsigneeBranchId] = useState(0);
  const [consigneebranchLabel, setconsigneeBranchLabel] = useState('');
  const [detinationBranchShowhide, setdetinationBranchShowhide] = useState('none');

  const [direct, setdirect] = useState(0);
  const [batchNo, setbatchNo] = useState('');
  const [courierType, setcourierType] = useState(0);
  const [refNo, setrefNo] = useState('');
  const [outwardType, setoutwardType] = useState(0);
  const [consigneeId, setconsigneeId] = useState(0);
  const [consigneeName, setconsigneeName] = useState('');
  const [consigneePhoneNumber, setconsigneePhoneNumber] = useState('');
  const [consigneeEmailId, setconsigneeEmailId] = useState('');
  const [consigneeCityName, setconsigneeCityName] = useState('');
  const [consigneeZipCode, setconsigneeZipCode] = useState('');
  const [consigneeAreaName, setconsigneeAreaName] = useState('');
  const [consigneeStateName, setconsigneeStateName] = useState('');
  const [consigneeCountryCode, setconsigneeCountryCode] = useState('');
  const [employeeTableId, setemployeeTableId] = useState(0);
  // const [department, setdepartment] = useState('');
  const [remark, setremark] = useState('');
  const [product, setproduct] = useState(0);
  const [AWB, setAWB] = useState('');
  const [numberOfPieces, setnumberOfPieces] = useState<any | 0>(1);
  const [weight, setweight] = useState<any | 0.00>(0);
  const [volumetricWeight, setvolumetricWeight] = useState<any | 0.00>(0);
  const [chargableWeight, setchargableWeight] = useState<any | 0.00>(0);
  // const [serviceLocation, setserviceLocation] = useState(0);
  // const [serviceRequest, setserviceRequest] = useState(0);
  const [productName, setproductName] = useState('');
  const [allProduct, setallProduct] = useState([]);
  const [mailContent, setmailContent] = useState('');
  const [shipmentType, setshipmentType] = useState(0);
  const [zoneName, setzoneName] = useState('');
  const [zoneId, setzoneId] = useState(0);
  const [deliveryTat, setdeliveryTat] = useState('');
  const [serviceStatus, setserviceStatus] = useState(0);
  const [netAmount, setnetAmount] = useState<any | 0.00>(0);
  const [fuelCharges, setfuelCharges] = useState<any | 0.00>(0);
  const [otherCharges, setotherCharges] = useState<any | 0.00>(0);
  const [GST, setGST] = useState<any | 0.00>(0);
  const [grossAmount, setgrossAmount] = useState<any | 0.00>(0);


  const [pinvalue, setpinvalue] = useState([]);
  const [pincodeId, setPincodeId] = useState(0);
  const [boxlength, setboxLength] = useState(0);
  const [boxwidth, setBoxWidth] = useState(0);
  const [boxheight, setBoxHeight] = useState(0);
  const [hideshowtable, sethideshowtable] = useState(true)
  const [allPendigbatches, setallPendigbatches] = useState<any[]>([])
  // const [inputValue, setInputValue] = useState('');
  const [fromAccount, setFromAccount] = useState([])
  const [accountNo, setAccountNO] = useState(0);
  const [accountNoLable, setAccountNoLable] = useState('')

  const [optionsMailContent, setoptionsMailContent] = useState<any | []>([]);
  const [selectedOptionsmailContent, setSelectedOptionsmailContent] = useState(null);

  const [showtable, setShowTable] = useState(false)
  const [showform, setShowForm] = useState(true)
  const [lockUnlock, setlockUnlock] = useState(false);
  const [maxqty, setmaxqty] = useState(0);


  // Load suggestions from local storage on component mount
  useEffect(() => {
    const storedOptionsmailContent = localStorage.getItem("mailContentSuggestions");
    if (storedOptionsmailContent) {
      setoptionsMailContent(JSON.parse(storedOptionsmailContent));
    }
  }, []);

  // Update suggestions in local storage whenever options change
  useEffect(() => {
    if (optionsMailContent.length > 0) {
      localStorage.setItem("mailContentSuggestions", JSON.stringify(optionsMailContent));
    }
  }, [optionsMailContent]);

  // Handle input value change
  const handleInputChangeMailContent = (newValue: any) => {
    setmailContent(newValue);
  };

  // Handle option creation
  const handleCreateOptionMailContent = (mailContents: any) => {
    setmailContent(mailContents);
    const newOption: any = { label: mailContents, value: mailContents };
    setoptionsMailContent([...optionsMailContent, newOption]);
    setSelectedOptionsmailContent(newOption);
  };

  // Handle option selection
  const handleOptionChangeMailContent = (selectedOption: any) => {
    setSelectedOptionsmailContent(selectedOption);
  };

  const pendingBatchesList = () => {
    OutwardLogService.getOutwardLogPendingList().then((data) => {
      if (Array.isArray(data)) {
        // console.log(data)
        setallPendigbatches(data)
      }
    })
  }
  useEffect(() => {

    getShipmentMode();
    pendingBatchesList();
    // getServiceProvider();
    getAllEmployee();
    getCostCenter();
    getBranch();
    console.log("outwardTime", outwardTime)
    if (state !== null) {
      getOutwardDetailsById()
    }
  }, [])

  const getOutwardDetailsById = async () => {
    if (state !== null) {
      await setIsChecked(false)
      await sethideshowtable(false)
      await OutwardDetailService.getOutwardDetailsById(state).then((data) => {
        if (data) {
          console.log("data Outward Details", data)
          setmodeId(data.modeName)
          setcourierType(data.courierType)
          setconsigneeAreaName(data.consigneeAddress)
          setconsigneeBranchId(data.consigneeBranchId)
          setcostCenter(data.costCenter)

          setconsigneeName(data.consigneeName)
          setconsigneeZipCode(data.consigneeZipCode)
          setconsigneeAreaName(data.consigneeAreaName)
          setconsigneePhoneNumber(data.consigneePhoneNumber)
          setrefNo(data.refNo)
          setremark(data.remark)
          setAWB(data.AWB)
          setserviceProviderId(data.serviceProvider)
          setproduct(data.product)
          setproductName(data.productName)
          setoutwardTime((moment(data.outwardTime)).format('HH:mm'))
          setoutwardDate(data.outwardDate)
          setbatchNo(data.batchNo)
          setconsigneeCityName(data.consigneeCityName)
          setconsigneeStateName(data.consigneeStateName)
          setconsigneeCountryCode(data.consigneeCountryCode)
          setemployeeId(data.employeeId)
          setAccountNO(data.accountNo)
          setEmployeeName(data.employeeName)
          setnumberOfPieces(data.numberOfPieces)
          setmailContent(data.mailContent)
          setboxLength(data.boxlength)
          setshipmentType(data.shipmentType)
          setBoxHeight(data.boxheight)
          setBoxWidth(data.boxwidth)
          setvolumetricWeight(data.volumetricWeight)
          setweight(data.weight)
          setchargableWeight(data.chargableWeight)
          setnetAmount(data.netAmount)
          setGST(data.gstAmount)
          setotherCharges(data.otherCharges)
          setgrossAmount(data.grossAmount)
          setoutwardType(data.outwardType)
          setdeliveryTat(data.deliveryTat)
          setserviceStatus(data.serviceStatus)
          // if (data.outwardType === 0) {
          //   setconsigneeId(0)
          // } else {
          //   setconsigneeId(data.consigneeId)
          // }
          setconsigneeId(data.outwardType === 0 ? 0 : data.outwardType ?? data.consigneeId);
          setdeliveryCheck(data.outwardDetailStatus !== 8 || data.outwardDetailStatus !== 9);
          // if(data.outwardDetailStatus == 8 || data.outwardDetailStatus == 9) {
          //   setdeliveryCheck(true)
          // }else {
          //   setdeliveryCheck(false)
          // }
          // if(data.consigneeId !== null){
          //   setconsigneeId(data.consigneeId)
          // } else {
          //   setconsigneeId(0)  
          // }
        }
      })

    }
  }

  const getShipmentMode = () => {
    MasterDataService.GetshipmentMode().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.modeName,
            // "multiplyFactor": d.multiplyFactor
          }))
          await setshipmentmode(options)
          if (state === null) {
            // if (data[0]) {
            //   await setmodeId(data[0]['id'])
            //   await setmodeName(data[0]['modeName'])
            //   await setmultiplyFactor(data[0]['multiplyFactor'])
            // }
          }
        }
      }
    );
  }
  useEffect(() => {
    let modesNames = shipmentmode.find((item: any) => {
      return item['value'] === modeId
    });
    if (modesNames !== undefined) {
      setmodeName(modesNames['label'])
      // setmultiplyFactor(modesNames['multiplyFactor'])
    }
  }, [modeId, shipmentmode])

  // const getServiceProvider = () => {
  //   MasterDataService.GetserviceProvider().then(
  //     async (data) => {
  //      if (data) {
  //         const options = data.map((d: any) => ({
  //           "value": d.id,
  //           "label": d.serviceProviderName
  //         }))
  //         await setserviceProviderList(options)
  //         if (state === null) {
  //           if (data[0]) {
  //             await setserviceProviderId(data[0]['id'])
  //             await setserviceProvider(data[0]['serviceProviderName'])
  //           }
  //         } else {
  //           let serviceProviderLists = data.find((item: any) => {
  //             return item.id === serviceProviderId
  //           });
  //           if (serviceProviderLists !== undefined) {
  //             setserviceProvider(serviceProviderLists.serviceProviderName)
  //           }
  //         }
  //       }
  //     }
  //   )
  // }
  const getAllEmployee = async () => {
    await MasterDataService.getEmployeeList().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.employeeName,
            'costCenter': d.costCenter,
            'employeeId': d.employeeId,
            'departmentName': d.departmentName
          }))
          setAllEmployee(options);
          if (state === null) {
            // if (data[0]) {
            //   setemployeeTableId(data[0]['id'])
            //   await setemployeeId(data[0]['employeeId'])
            //   await setEmployeeName(data[0]['employeeName'])
            //   setcostCenter(data[0]['costCenter'])
            // }
          }

        }
      }
    );
  }
  useEffect(() => {
    setEmployee();
  }, [employeeTableId, allEmployee])
  const setEmployee = async () => {
    let employeeLists = allEmployee.find((item: any) => {
      return item['value'] === employeeTableId
    });
    if (employeeLists !== undefined) {
      setEmployeeName(employeeLists['label'])
      setcostCenter(employeeLists['costCenter'])
    }
  }
  const getCostCenter = async () => {
    await MasterDataService.getCostCenter().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.costCenter
          }))
          await setallCostCenter(options)
          // if (state === null) {
          //   await setcostCenter(data[0]['id'])
          //   setcostCenterLabel(data[0]['costCenter'])
          // }
        }
      }
    )
  }
  useEffect(() => {
    setCoseCenter();
  }, [costCenter, allCostCenter])
  const setCoseCenter = () => {
    let costCenters = allCostCenter.find((item: any) => {
      return item['value'] === costCenter
    });
    if (costCenters !== undefined) {
      setcostCenterLabel(costCenters['label'])
    }
  }

  // useEffect(() => {
  //   getPincode();
  // }, [courierType])
  const handleInputChange = async (input: any) => {
    if (input.length > 3) {
      return await MasterDataService.getAllPincodeByCountryWiseAutoComplte(courierType, input).then(
        (data) => {
          const zipCodeList = data.map((d: any) => ({
            "value": d.id,
            "label": d.zipCode
          }))
          return zipCodeList;
          // setpinvalue(zipCodeList)
          // if (state === null) {
          //   if (data[0]) {
          //     setPincodeId(data[0]['id'])
          //     setconsigneeZipCode(data[0]['zipCode'])
          //   }
          // }
        }
      )
    }
  };
  const getPincode = async () => {
    await MasterDataService.getAllPincodeByCountryWise(courierType).then(
      (data) => {
        const zipCodeList = data.map((d: any) => ({
          "value": d.id,
          "label": d.zipCode
        }))
        setpinvalue(zipCodeList)
        if (state === null) {
          // if (data[0]) {
          //   setPincodeId(data[0]['id'])
          //   setconsigneeZipCode(data[0]['zipCode'])
          // }
        }
      }
    )
  }

  const setPincodeDetails = () => {
    let pinCodeLists = pinvalue.find((item: any) => {
      return item['value'] === pincodeId
    });
    if (pinCodeLists !== undefined) {
      setconsigneeZipCode(pinCodeLists['label'])
    }
  }

  useEffect(() => {
    // console.log("consigneeZipCode", consigneeZipCode)
    if (consigneeZipCode.length > 3) {
      setPincodeDetails()
      getCityStateCountryInfo(consigneeZipCode)
    }
  }, [pinvalue, pincodeId])

  const getCityStateCountryInfo = async (value: any) => {
    await MasterDataService.getPincodeCityInfo(value).then(
      (data) => {
        if (data) {
          setconsigneeCityName(data.cityName)
          setconsigneeStateName(data.stateName)
          setconsigneeCountryCode(data.countryName)
        }
      }
    )
  }

  //  get branch options
  const getBranch = async () => {
    await MasterDataService.getBranch().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.branchName
          }))
          await setallBranch(options)
          if (state === null) {
            // if (data[0]) {
            //   await setconsigneeBranchId(data[0]['id'])
            //   await setconsigneeBranchLabel(data[0]['branchName'])
            // }
          } else {
            let branchLists = data.find((item: any) => {
              return item.id === consigneeBranchId
            });
            if (branchLists !== undefined) {
              setconsigneeBranchLabel(branchLists.branchName)
            }
          }
        }
      }
    )
  }
  const handleBranchChange = async (e: any) => {
    await setconsigneeBranchId(e.value)
    await setconsigneeBranchLabel(e.label)
    getBranchEmployeeList(e.value);
  };
  const getBranchEmployeeList = async (value: Number) => {
    await MasterDataService.getBranchEmployeeByBranchId(value).then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.employeeName,
            "consigneeAddress": d.fullAddress,
            "pincode": d.detailedZipCode,
            "pincodeId": d.zipCode,
            "emailid": d.notifyOnMail,
            "phoneNo": d.phoneNumber

          }))
          await setallSelections(options)
          if (state === null) {
            // if (data[0]) {
            //   await setconsigneeId(data[0]['id'])
            //   await setconsigneeName(data[0]['employeeName'])
            //   await setconsigneeAreaName(data[0]['consigneeAddress'])
            //   await setPincodeId(data[0]['zipcode'])
            // }
          } else {
            let branchwiseEmployeeLists = data.find((item: any) => {
              return item.id === consigneeId
            });
            if (branchwiseEmployeeLists !== undefined) {
              setconsigneeName(branchwiseEmployeeLists.employeeName)
              await setconsigneeAreaName(branchwiseEmployeeLists.consigneeAddress)
              await setPincodeId(branchwiseEmployeeLists.pincodeId)
              await setconsigneeEmailId(branchwiseEmployeeLists.emailid)
              await setconsigneePhoneNumber(branchwiseEmployeeLists.phoneNo)
              console.log("setPincodeId", setPincodeId);
            }
          }
        }
      }
    )
  }
  const onToDestinationChange = (value: Number) => {
    if (value === 1) {
      setdetinationBranchShowhide('flex')
      setoutwardType(1)
    } else {
      setdetinationBranchShowhide('none')
      setoutwardType(0)
    }
  }


  useEffect(() => {
    getServiceProviderbypincode()
  }, [consigneeZipCode])
  const getServiceProviderbypincode = async () => {
    if (consigneeZipCode !== undefined) {
      await OutwardDetailService.getServiceProviderbyPincode(consigneeZipCode, modeId).then(
        (data) => {
          if (data.length !== 0) {
            const options: any = data.map((d: any) => ({
              "value": d.serviceProviderId,
              "label": d.serviceProviderName,
              "zoneId": d.zoneId,
              "zoneName": d.zoneName,
              "deliveryTat": d.serviceTat,
              "serviceable": "Yes"
            }))
            setserviceProviderList(options)
            if (state === null) {
              if (data[0]) {
                setserviceProviderId(data[0]['serviceProviderId'])
                setserviceProvider(data[0]['serviceProviderName'])
                setzoneName(data[0]['zoneName'])
                setzoneId(data[0]['zoneId'])
                setdeliveryTat(data[0]['serviceTat'])
                setserviceStatus(1)
              }
            }
          } else {
            setserviceProviderId(0)
            setserviceProvider('')
            setzoneName('')
            setzoneId(0)
            setdeliveryTat('')
            setserviceStatus(0)
          }
        }
      )
    }
  }
  const setServiceProviderfn = () => {
    let serviceProviderLists = serviceProviderList.find((item: any) => {
      return item['value'] === serviceProviderId
    });
    if (serviceProviderLists !== undefined) {
      setserviceProviderId(serviceProviderLists['value'])
      setserviceProvider(serviceProviderLists['label'])
      setzoneName(serviceProviderLists['zoneName'])
      setzoneId(serviceProviderLists['zoneId'])

      // console.log("serviceProviderLists['zoneId']", serviceProviderLists['zoneId'])
    }
  }

  useEffect(() => {
    setServiceProviderfn();
  }, [serviceProviderId, serviceProviderList])
  useEffect(() => {
    getProduct(serviceProviderId);
  }, [serviceProviderId, zoneId])
  const getProduct = async (value: number) => {
    if (value !== 0) {
      await AccountService.getProductsByServiceProviderandZone(value, branch, zoneId).then(
        async (data) => {
          // console.log("Products Data", data)
          if (data.length > 0) {
            const options = data.map((d: any) => ({
              "value": d.id,
              "label": d.productName,
              "cftValue": d.cftValue
            }))
            await setallProduct(options)
            if (state === null) {
              if (data[0]) {
                await setproduct(data[0]['id'])
                await setproductName(data[0]['productName'])
                await setmultiplyFactor(data[0]['cftValue'])
              }
            }
          }
          else {
            await setproduct(0)
            await setproductName('')
            setallProduct([])
          }
        }
      )
    }
    else {
      await setproduct(0)
      await setproductName('')
      setallProduct([])
    }
  }

  //   useEffect(() => {
  //     getZonebyPincodeandProduct()
  //   },[consigneeZipCode, product])

  //   const getZonebyPincodeandProduct = async () => {
  // await OutwardDetailService.getZonebyPincodeandProduct(product, consigneeZipCode).then((data)=>{
  //   console.log(data)
  // })
  //   }

  const handleEdit = () => {
    OutwardDetailService.putoutwardData(direct, outwardType, batchNo, courierType, modeId, outwardDate, outwardTime, employeeTableId, employeeName, employeeId, costCenter, refNo, AWB, consigneeId, consigneeName, consigneeAreaName, consigneeBranchId, consigneeCityName, consigneePhoneNumber, consigneeEmailId, consigneeStateName, consigneeZipCode, consigneeCountryCode, serviceProviderId, productName, product, zoneId, boxlength, boxwidth, boxheight, volumetricWeight, numberOfPieces, weight, chargableWeight, netAmount, fuelCharges, otherCharges, GST, grossAmount, mailContent, shipmentType, accountNo, remark, deliveryTat, serviceStatus, state).then(
      (data) => {
        if (data) {
          let osrnum = JSON.stringify(data.message.osrNumber);
          if (!lockUnlock) {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: "OSR Number : " + osrnum + " Details Updated Successfully",
              showConfirmButton: true,
              // timer: 1500,
            }).then(() => {
              navigate('../outwarddetailslist');
            });
          }
          else {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: "OSR Number : " + osrnum + " Details Updated Successfully",
              showConfirmButton: true,
              // timer: 1500,
              customClass: {
                title: 'swal-fire-alert', // Add your custom CSS class here
              },
            })
            setEntryDoneQty(parseFloat(entryDoneQty) + parseFloat(numberOfPieces))
          }
        }
      }
    )
  }
  const handleDelete = () => {

  }
  const handleSubmit = () => {
    if (batchNo === "") {
      setdirect(1)
    }
    if ((courierType !== null) && (modeId !== 0) && (employeeTableId !== 0) && (consigneeName !== "") && (consigneeZipCode !== "")) {

      OutwardDetailService.postoutwardData(direct, outwardType, batchNo, courierType, modeId, outwardDate, outwardTime, employeeTableId, employeeName, employeeId, costCenter, refNo, AWB, consigneeId, consigneeName, consigneeAreaName, consigneeBranchId, consigneeCityName, consigneePhoneNumber,consigneeEmailId ,consigneeStateName, consigneeZipCode, consigneeCountryCode, serviceProviderId, productName, product, zoneId, boxlength, boxwidth, boxheight, volumetricWeight, numberOfPieces, weight, chargableWeight, netAmount, fuelCharges, otherCharges, GST, grossAmount, mailContent, shipmentType, accountNo, remark, deliveryTat, serviceStatus).then(
        (data) => {
          if (data) {
            let osrnum = JSON.stringify(data.outwardDetails.osrNumber);
            if (!lockUnlock) {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: "OSR Number : " + osrnum + " Details Inserted Successfully",
                showConfirmButton: true,
                // timer: 1500,
              }).then(() => {
                navigate('../outwarddetailslist');
              });
            }
            else {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: "OSR Number : " + osrnum + " Details Inserted Successfully",
                showConfirmButton: true,
                // timer: 1500,
                customClass: {
                  title: 'swal-fire-alert', // Add your custom CSS class here
                },
              })
              setEntryDoneQty(parseFloat(entryDoneQty) + parseFloat(numberOfPieces))
            }
          }
        }
      )

    } else {
      let message = "Please Select "
      if ((courierType === 0) && (modeId === 0) && (employeeTableId === 0) && (consigneeName === "") && (consigneeZipCode === "")) {
        message += "Compulsary Fields";
      } else if (courierType === null) {
        message += "Courier Type";
      } else if (modeId === 0) {
        message += "Shipment Mode";
      } else if (employeeTableId === 0) {
        message += "Employee / Consignor";
      } else if (consigneeName === "") {
        message += "Consignee";
      } else if (consigneeZipCode === "") {
        message += "Consignee Pincode";
      }
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }
  // const opendialagforPreviousBatch = () => {
  //   pendingBatchesList()
  //   sethideshowtable(true)
  // }

  const clearForm = () => {
    setbatchNo('')
  }

  //----------------- Calculations --------------

  useEffect(() => {
    setVolumatricWeightFn()
  }, [boxwidth, boxheight, boxlength, modeId])

  const setVolumatricWeightFn = () => {
    if ((multiplyFactor !== null) && (multiplyFactor !== 0) && (multiplyFactor !== undefined)) {
      console.log("multiplyFactor", multiplyFactor)
      const calculatedWeight = (boxwidth * boxheight * boxlength / 2.70 * multiplyFactor).toFixed(3);
      setvolumetricWeight(calculatedWeight);
    }
  }
  useEffect(() => {
    calculateHighstWeight()
  }, [weight, volumetricWeight])

  let highestWeight = 0
  const calculateHighstWeight = () => {
    if (weight !== "" && volumetricWeight !== null) {

      if (volumetricWeight !== 0 || weight !== 0) {
        if (volumetricWeight > weight) {
          highestWeight = volumetricWeight
        } else {
          highestWeight = weight
        }
      }
    }
  }
  useEffect(() => {
    getRateChartsByProductandZone()
  }, [modeId, serviceProviderId, product, zoneId])

  const getRateChartsByProductandZone = () => {
    // console.log("Rate contract Change", product)
    if ((modeId !== 0) && (serviceProviderId !== 0) && (product !== 0) && (zoneId !== 0)) {

      AccountService.getRateContractByProductandzones(modeId, serviceProviderId, product, zoneId).then((data) => {
        setRatesContract(data)
        // console.log("Rate contract Change", data)
      })
    }

  }

  useEffect(() => {
    calculateNetAmount()
  }, [weight, volumetricWeight, product, serviceProviderId])
  const calculateNetAmount = () => {
    // calculate 
    // console.log("ratesContract call", ratesContract)
    if ((ratesContract !== "") && (ratesContract !== null)) {
      // console.log("ratesContract", ratesContract)
      const additionalWeight = ratesContract.additionalWeight
      const additionalRate = ratesContract.additionalRate
      const surcharge = ratesContract.surcharge
      const fuelSurcharge = ratesContract.fuelSurcharge
      // const loadingCharge = ratesContract.loadingCharge
      // const unloadingCharge = ratesContract.unloadingCharge
      const rateContractDetail = ratesContract.rateContractDetail

      console.log("additional Weight", additionalWeight, " Weight :", highestWeight)
      console.log("Highest Weight", highestWeight)
      console.log("rates", rateContractDetail)
      let chargebaleWeight = highestWeight

      let selectedSlab = null;
      let difference = 0;
      if ((rateContractDetail.length > 0) && highestWeight !== 0) {
        for (const slab of rateContractDetail) {
          if (highestWeight >= slab.slabStart && highestWeight <= slab.slabEnd) {
            selectedSlab = slab;
            break; // Found the matching slab, no need to continue
          }
        }

        if (!selectedSlab) {
          // If no matching slab found, use the highest slabEnd slab
          const highestSlab = rateContractDetail.reduce((maxSlab: any, currentSlab: any) =>
            currentSlab.slabEnd > maxSlab.slabEnd ? currentSlab : maxSlab
          );
          selectedSlab = highestSlab;
          difference = highestWeight - highestSlab.slabEnd;
        }
      }
      let additionWeightSlab = 0;
      let docCharges: any = 0;
      additionWeightSlab = Math.ceil(difference / additionalWeight);
      if (selectedSlab) {
        docCharges = (selectedSlab.slabRate + additionWeightSlab * additionalRate).toFixed(2)
        chargebaleWeight = selectedSlab.slabEnd + additionWeightSlab * additionalWeight;
      }
      setnetAmount(parseFloat(docCharges));
      setchargableWeight(chargebaleWeight)

      let fuelSurc = (docCharges * fuelSurcharge / 100).toFixed(2)
      setfuelCharges(parseFloat(fuelSurc))

      let idcCharges = (docCharges * surcharge / 100).toFixed(2)
      setotherCharges(parseFloat(idcCharges))

    }
  }
  const handlemailcontent = async (e: any) => {
    if (e) {
      await setmailContent(e.value);
    }
  }
  useEffect(() => {
    calculateGrandTotalFn();
  }, [netAmount, otherCharges])

  const calculateGrandTotalFn = () => {
    let gstAmount = ((netAmount + fuelCharges + otherCharges) * 0.18).toFixed(2)
    setGST(parseFloat(gstAmount))
    if (otherCharges === "") {
      setotherCharges(0)
    }
    let grossAmt = (parseFloat(gstAmount) + parseFloat(otherCharges) + parseFloat(fuelCharges) + parseFloat(netAmount)).toFixed(2)
    setgrossAmount(parseFloat(grossAmt))
  }
  //--------------------Calculations End ------------

  useEffect(() => {
    OutwardDetailService.vendoraccount(serviceProviderId).then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.accountNo,
          }))
          await setFromAccount(options)
          // if (state === null && accountNo === 0) {
          //   if (data[0]) {
          //     await setAccountNO(data[0]['id'])
          //     await setAccountNoLable(data[0]['accountNo'])
          //   }
          // }
        }
      }
    )
  }, [serviceProviderId, accountNo, state])

  const ShowTableFn = () => {
    if (lockUnlock === true) {
      setShowForm(true)
      setShowTable(false)
    }
    else if (state === null) {
      setShowForm(!showform)
      setShowTable(!showtable)
    } else {
      setShowTable(false)
      setShowForm(true)
    }
  }

  const lockBatchNumber = () => {
    if (batchNo !== "") {
      setlockUnlock(!lockUnlock);
    }
  }

  const checkEntryType = () => {
    setIsChecked(!isChecked);
  }

  useEffect(() => {
    if (isChecked === true) {
      sethideshowtable(false)
    } else {
      sethideshowtable(true)
    }
  }, [isChecked])


  const handleConsigneeChange = (e: any) => { if (e !== null) { setconsigneeId(e.value); setconsigneeName(e.label); if(e.emailid){ setconsigneeEmailId(e.emailid); } 
  if(e.phoneNo){setconsigneePhoneNumber(e.phoneNo); } 
  if(e.pincodeId) { setPincodeId(e.pincodeId); } 
  if(e.pincode) { setconsigneeZipCode(e.pincode)} } }
  return (
    <>
      <Outcontainerheader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11">
            <div className="themeColor" style={{ width: '100%', paddingLeft: '0px', paddingRight: '0px' }} ><p style={{ marginLeft: "10px" }}> Outward Details</p></div>
            <div className='width card'>
              <div className='width inline section-1 margin-left'>
                <div className='col-3 inline sec4-radio'>
                  <label className='col-4'>  Mode </label>
                  <Select
                    name="modeId"
                    onChange={(e: any) => { setmodeId(e.value); setmodeName(e.label) }}
                    options={shipmentmode}
                    className='col-8 select'
                    value={{ value: modeId, label: modeName }}

                    styles={customStyles} />
                </div>
                <div className='col-2 inline sec4-radio'>
                  <div className='sec4-radio1 direct1'>
                    <label className='inline' ><input
                      name="connected"
                      id="home"
                      type="radio"
                      value="first"
                      className="sec1-radio radio-btn"
                      onChange={() => { setcourierType(0); }}
                      checked={courierType === 0 && true}
                    />Domestic </label>
                  </div>
                  <div className='sec4-radio1 direct1'>
                    <label className='inline' ><input
                      name="connected"
                      id="home"
                      type="radio"
                      value="first"
                      onChange={() => { setcourierType(1); }}
                      checked={courierType === 1 && true}
                      className=" sec1-radio radio-btn "
                    />International</label>
                  </div>
                </div>
                <div className='col-4 inline' >
                  <label className='col-4'>Batch No <br />

                    <input type='checkbox' checked={isChecked} onChange={checkEntryType} />Direct Entry</label>
                  <input className=" col-sm-6 zeroPadding" type="text" value={batchNo} name='batchNo' onChange={e => setbatchNo(e.target.value)} onClick={ShowTableFn} /><button className={"col-sm-2" + (lockUnlock) ? "themeColor" : "greenBackground"} style={{ backgroundColor: (lockUnlock) ? '' : 'green', color: 'white', padding: 0, width: '100%' }} onClick={lockBatchNumber}>{(lockUnlock) ? "Locked" : "Lock"}</button>

                  {/* <input className='col-6' type="text" value={batchNo} onClick={opendialagforPreviousBatch} /> */}
                  {/* <div className='col-2'><button className='green-btn'>Lock</button> </div> */}
                </div>
                <div className='col-3 inline' >
                  <div className=''> <span className='direct'>Date & Time</span></div>
                  <div className=''> <input className='direct' type="date" name="outwardDate" max={moment().format("YYYY-MM-DD")} value={outwardDate} onChange={(e: any) => { setoutwardDate(e.target.value) }} /></div>
                  <div className=''> <input className='' type="time" name="setoutwardTime" value={outwardTime} onChange={(e: any) => { setoutwardTime(e.target.value) }} /></div>

                </div>
              </div>
              {(batchNo !== "") && (<div className='col-12 text-right' style={{ fontStyle: 'italic', fontSize: '10px', color: 'blue' }}> {entryDoneQty} out of {maxqty}</div>)}
              {hideshowtable && (<div className='col-12 inline'>
                <table className="table1 container">
                  <thead className="" style={{ textAlign: 'left' }}>

                    <tr style={{ textAlign: "left", border: "1px solid grey" }} >
                      <th scope="col" style={{ textAlign: 'left' }}>Sr. No.</th>
                      <th scope="col" style={{ textAlign: 'left' }}>Date</th>
                      <th scope="col" style={{ textAlign: 'left' }}>Batch No</th>
                      <th scope="col" style={{ textAlign: 'left' }}>Employee</th>
                      <th scope="col" style={{ textAlign: 'left' }}>Pcs</th>
                      <th scope="col" style={{ textAlign: 'left' }}  >Remark</th>
                    </tr>

                  </thead>


                  <tbody className="width  " style={{ border: "1px solid grey" }}>
                    {allPendigbatches.map((outward: any, index) => {
                      if (outward.pendingQuantity > 0) {
                        return (<>
                          <tr key={outward.id} onClick={() => {
                            sethideshowtable(false);
                            setbatchNo(outward.batchNo);
                            setemployeeTableId(outward.employeeTableId);
                            setemployeeId(outward.employeeId);
                            setEmployeeName(outward.employeeName);
                            setcostCenter(outward.costCenter);
                            setcostCenterLabel(outward.costCenter);
                            setmodeId(outward.modeName);
                            setmaxqty(outward.noOfShipment);
                          }} >
                            <th scope="row" >{index + 1}</th>
                            <td>{outward.outwardDate}</td>
                            <td>{outward.batchNo}</td>
                            <td> {outward.employeeName}</td>
                            <td>{outward.noOfShipment}</td>
                            <td>{outward.remarks}</td>
                          </tr>
                        </>)
                      } else {
                        return null;
                      }
                    })}

                  </tbody>

                </table>
              </div>)}
              {!hideshowtable && (<div>
                <div className='col-12 inline' style={{ backgroundColor: 'grey', height: 25, color: 'white', textAlign: 'left', marginBottom: '5px' }} >
                  <div className='col-6' style={{ height: 25 }}>
                    <label className='themeColor' style={{ color: 'white', paddingLeft: '10px' }}>From Source &nbsp; </label> <label> Consignor Details    </label>
                  </div>
                  <div className='col-6' style={{ height: 25, paddingLeft: '0px' }}>
                    <label className='themeColor' style={{ color: 'white', paddingLeft: '10px' }}>To Consignee Destination &nbsp;</label> <label> <input type="radio" name="todestimationbranch" style={{ height: '12px' }} value="1" onChange={() => { onToDestinationChange(1) }} checked={outwardType === 1 && true} /> Inter Company  &nbsp;  &nbsp; </label>    <label> <input type="radio" name="todestimationbranch" style={{ height: '12px' }} value="0" onChange={() => { onToDestinationChange(0) }} checked={outwardType === 0 && true} /> Others Destination </label>
                  </div>

                </div>


                <div className='col-12 inline' style={{ marginBottom: '5px' }} >

                  <div className='col-6' >
                    <div className='col-12 inline'>
                      <label className='col-4'>Consignor</label>
                      <div className='col-8' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <Select onChange={(e: any) => { if (e !== null) { setemployeeId(e.employeeId); setEmployeeName(e.label); setemployeeTableId(e.value); setdepartmentName(e.departmentName) } }}
                          options={allEmployee}
                          value={{ value: employeeTableId, label: employeeName }}
                          styles={customStyles} />
                      </div>
                    </div>
                    <div className='col-12 inline'>
                      <label className='col-4'>Cost Center</label>
                      <div className='col-8' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <Select
                          name="costCenter"
                          onChange={(e: any) => { setcostCenterLabel(e.label); setcostCenter(e.value) }}
                          options={allCostCenter}
                          value={{ value: costCenter, label: costCenterLabel }}
                          styles={customStyles}
                        />
                      </div>
                    </div>

                    <div className='col-12 inline'>
                      <label className='col-4'>Department</label>
                      <label className='col-8'>  {departmentName}</label>
                    </div>

                    <div className='col-12 inline'>
                      <label className='col-4'>Employee Id :</label>
                      <label className='col-8'>  {employeeId}</label>
                    </div>
                    {/* <div className='col-12 inline'>
                    <label className='col-4'>Branch :  {branch}</label>
                  </div> */}


                    <div className='col-12 inline'>
                      <label className='col-4'>Ref No : </label>
                      <input className='col-8' type="text" value={refNo} onChange={(e: any) => { setrefNo(e.target.value); }} />
                    </div>

                    <div className='col-12 inline'>
                      <label className='col-4'>AWB No</label>
                      <input className='col-8' type="text" value={AWB} onChange={(e: any) => { setAWB(e.target.value); }} />
                    </div>

                  </div>
                  <div className='col-6' style={{ borderLeft: "1px solid #ccc" }} >
                    <div className='col-12 inline' style={{ display: detinationBranchShowhide }}>
                      <label className='col-3'>Destn. Branch</label>
                      <div className='col-9' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <Select
                          name="consigneeBranchId"
                          onChange={handleBranchChange}
                          options={allBranch}
                          value={{ value: consigneeBranchId, label: consigneebranchLabel }}
                          styles={customStyles}
                        />
                      </div>
                    </div>
                    <div className='col-12 inline'>
                      <label className='col-3'>Consignee</label>
                      <div className='col-9' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        {state === null ? (<>
                          <CreatableSelect isClearable options={allSelections}
                            defaultValue={consigneeId}
                            onChange={handleConsigneeChange}
                          />
                        </>) : <>
                          <input className='col-12' type="text" value={consigneeName} onChange={(e: any) => { setconsigneeName(e.target.value); }} />
                        </>}
                      </div>
                    </div>
                    <div className='col-12 inline'>
                      <label className='col-3'>Phone No </label>
                      <input className='col-3' type="text" value={consigneePhoneNumber} onChange={(e: any) => { setconsigneePhoneNumber(e.target.value); }} />
                      <label className='col-2'>EMail Id</label>
                      <input className='col-4' type="text" value={consigneeEmailId} onChange={(e: any) => { setconsigneeEmailId(e.target.value); }} />
                    </div>

                    <div className='col-12 inline'>
                      <label className='col-3'>Pincode </label>
                      <div className='col-4' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <AsyncSelect
                          name="pincode"
                          // onInputChange={(input) => setInputValue(input)}
                          onChange={(e: any) => { setPincodeId(e.value); setconsigneeZipCode(e.label); }}
                          loadOptions={handleInputChange}
                          value={{ value: pincodeId, label: consigneeZipCode }}
                          styles={customStyles}
                        />
                      </div>
                      <label className='col-5'>City :- {consigneeCityName} </label>
                      {/* <input className='col-4' type="text" value={consigneeCityName} onChange={(e: any) => { setconsigneeCityName(e.target.value); }} readOnly /> */}

                    </div>
                    <div className='col-12 inline'>
                      <label className='col-3'>Area Details</label>
                      <input className='col-8' type="text" value={consigneeAreaName} onChange={(e: any) => { setconsigneeAreaName(e.target.value); }} />
                    </div>
                    <div className='col-12 inline'>
                      <label className='col-3'>State </label>
                      <label className='col-3' >{consigneeStateName} </label>
                      {/* <input className='col-3' type="text" value={consigneeStateName} onChange={(e: any) => { setconsigneeStateName(e.target.value); }} readOnly /> */}

                      <label className='col-5'>Country :- {consigneeCountryCode} </label>
                      {/* <input className='col-3' type="text" value={consigneeCountryCode} onChange={(e: any) => { setconsigneeCountryCode(e.target.value); }} readOnly /> */}
                    </div>
                  </div>
                </div>
                <div className='col-12 inline' style={{ borderTop: "1px solid #ccc", paddingTop: '5px' }}   >
                  <div className='col-6'>
                    <div className='col-12 inline'>
                      <label className='col-4'>Service Provider</label>
                      <div className='col-8' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <Select
                          name="serviceProviderId"
                          onChange={(e: any) => {
                            setserviceProviderId(e.value);
                            setserviceProvider(e.label)
                          }}
                          options={serviceProviderList}
                          value={{ value: serviceProviderId, label: serviceProvider }}
                        />
                      </div>
                    </div>
                    <div className='col-12 inline'>
                      <label className='col-4'>To Account</label>
                      <div className='col-8' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <Select
                          name="fromAccount"
                          onChange={(e: any) => {
                            setAccountNO(e.value);
                            setAccountNoLable(e.label)
                          }}
                          options={fromAccount}
                          value={{ value: accountNo, label: accountNoLable }}
                        />
                      </div>
                    </div>
                    <div className='col-12 inline'>
                      <label className='col-4'>Product</label>
                      <div className='col-8' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <Select
                          name="product"
                          onChange={(e: any) => {
                            setproduct(e.value);
                            setproductName(e.label)
                            setmultiplyFactor(e.cftValue)
                          }}
                          options={allProduct}
                          value={{ value: product, label: productName }}
                        />
                      </div>
                    </div>
                    <div className='col-12 inline'>
                      <label className='col-4'>Zone</label>
                      <input type="text" value={zoneName} className='col-8' readOnly />
                    </div>
                    <div className='col-12 inline'>
                      <label className='col-6'>Delivery TAT :- <span style={{ color: "red", fontWeight: 'bold' }}>  <span style={{ paddingLeft: '20%' }}> {deliveryTat}  Hrs.</span>  </span> </label>
                      <label className='col-6'>Servicable  : <span style={{ color: "green", fontWeight: 'bold' }}> {serviceStatus === 0 ? 'No' : 'Yes'}</span> </label>
                    </div>
                    <div className='col-12 inline'>
                      <label className='col-4'>Content/Remark</label>
                      {/* <div className='col-8 select' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                                            <CreatableSelect
                                            inputValue={mailContent}
                                            options={optionsMailContent}
                                            onInputChange={handleInputChangeMailContent}
                                            onChange={handleOptionChangeMailContent}
                                            onCreateOption={handleCreateOptionMailContent}
                                            value={selectedOptionsmailContent}
                                            placeholder="Enter or select a Mail Content"
                                        />
                                        </div> */}

                      <div className='col-8 select' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                        <CreatableSelect
                          isClearable
                          options={optionsMailContent}
                          onChange={handlemailcontent}
                          onCreateOption={handleCreateOptionMailContent}
                          value={{ value: mailContent, label: mailContent }}
                          placeholder="Enter or select a Mail content"
                        />



                      </div>
                      {/* <input type="text" value={mailContent} onChange={(e) => setmailContent(e.target.value)} className='col-9' /> */}

                      {/* <input className='col-8' type="text" name="mailContent" value={mailContent} autoComplete='on' onChange={(e: any) => { setmailContent(e.target.value); }} /> */}
                    </div>
                    <div className='col-12 inline'>
                      <label className='col-4' > Remark </label>
                      <input type="text" value={remark} onChange={(e) => setremark(e.target.value)} className='col-8' />
                    </div>
                    <div className='col-12 inline'>
                      <label className='col-4'>Shipment Type </label>
                      <label className='col-4'><input type="radio" name="shipmentType" value="0" checked={shipmentType === 0 && true} onChange={() => { setshipmentType(0) }} /> Official </label>
                      <label className='col-4'> <input type="radio" name="shipmentType" value="1" checked={shipmentType === 1 && true} onChange={() => { setshipmentType(1) }} /> Personal </label>
                    </div>

                  </div>
                  <div className='col-6'>
                    <div className='col-12 inline'>

                      <label className='col-3'>No Of Pcs. </label>
                      <input className='col-3 text-right' type="text" value={numberOfPieces} onChange={(e: any) => { if (/^\d*\.?\d{0,2}$/.test(e.target.value)) { setnumberOfPieces(e.target.value); } }} />
                      <label className='col-3'>Act. Wt. </label>
                      <input className='col-3 text-right' type="text" value={weight} onChange={(e: any) => { if (/^\d*\.?\d{0,3}$/.test(e.target.value)) { setweight(e.target.value); } }} />

                    </div>

                    <div className='col-12 inline'>
                      <div className='col-6' style={{ paddingRight: '0px' }}>
                        <label className='col-3' style={{ padding: "0px" }}> Vol. LWH </label>
                        <input className='col-3' style={{ padding: "0px", textAlign: 'right' }} type="text" placeholder='Length' value={boxlength} onChange={(e: any) => { if (/^\d*\.?\d{0,3}$/.test(e.target.value)) { setboxLength(e.target.value) } }} />
                        <input className='col-3' style={{ padding: "0px", textAlign: 'right' }} type="text" placeholder='Width' value={boxwidth} onChange={(e: any) => { if (/^\d*\.?\d{0,3}$/.test(e.target.value)) { setBoxWidth(e.target.value); } }} />
                        <input className='col-3' style={{ padding: "0px", textAlign: 'right' }} type="text" placeholder='Height' value={boxheight} onChange={(e: any) => { if (/^\d*\.?\d{0,3}$/.test(e.target.value)) { setBoxHeight(e.target.value); } }} />
                      </div>

                      <label className='col-3'>Vol. Wt. </label>
                      <input className='col-3 text-right' type="text" value={volumetricWeight} onChange={(e: any) => { setvolumetricWeight(e.target.value); }} readOnly />

                    </div>
                    <div className='col-12 inline'>
                      {/* <label className='col-3'>Net Amt. </label>
                      <input className='col-3 text-right' type="text" value={netAmount} onChange={(e: any) => { setnetAmount(e.target.value); }} readOnly /> */}
                      <div className='col-6'></div>
                      <label className='col-3'> Charg. Wt.</label>
                      <input className='col-3 text-right' type="text" value={chargableWeight} readOnly />
                    </div>

                    <div className='col-12 inline'>
                      <label className='col-3'>Net Amt. </label>
                      <input className='col-3 text-right' type="text" value={netAmount} onChange={(e: any) => { setnetAmount(e.target.value); }} readOnly />

                      <label className='col-3'> Fuel SurC.</label>
                      <input className='col-3 text-right' type="text" value={fuelCharges} onChange={(e: any) => { setfuelCharges(e.target.value); }} readOnly />
                    </div>
                    <div className='col-12 inline'>
                      <label className='col-3'> GST (18%)</label>
                      <input className='col-3 text-right' type="text" value={GST} onChange={(e: any) => { setGST(e.target.value); }} readOnly />
                      <label className='col-3'> IDC  </label>
                      <input className='col-3 text-right' type="text" value={otherCharges} onChange={(e: any) => { if (/^\d*\.?\d{0,2}$/.test(e.target.value)) { setotherCharges(e.target.value); } }} />

                    </div>
                    <div className='col-12 inline'>
                      <div className='col-6'></div>
                      <label className='col-3'>Gross Total </label>
                      <input className='col-3 text-right' type="text" value={grossAmount} onChange={(e: any) => { setgrossAmount(e.target.value); }} readOnly />
                    </div>

                  </div>
                </div>
              </div>
              )}
            </div>

            <div>
              <div className='btn d-flex justify-content-center'>
                {(state === null) ? <button className="btn2 col-2 col-sm-1" type="button" onClick={handleSubmit}><i className="fa fa-save"></i> Save</button> : <>
                  {deliveryCheck && (<><button className="btn2 col-2 col-sm-1" onClick={handleEdit} ><i className="fa fa-edit"></i> Update </button>
                    <button className="btn2 col-2 col-sm-1" onClick={handleDelete}> <i className="fa fa-trash"></i> Delete</button> </>)} </>}
                <button className="btn2 col-2 col-sm-1" onClick={clearForm} > <i className="fa fa-close" ></i> Clear</button>
                <Link className="btn2 col-2 col-sm-1" to="/inwardloglist" type='button'>  <i className="fa fa-sign-out"></i> Close </Link>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

    </>

  )
}
export default Outwarddetailentry
