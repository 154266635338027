import React, { useEffect, useState } from 'react'
import MasterHeader from './masterHeader'
import MasterDataService from '../../services/masterData.service'
import { Link, useNavigate } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import { read, utils } from 'xlsx';

function EmployeeImport() {
  const createdBy =  localStorage.getItem('userId');
  const updatedBy =  localStorage.getItem('userId');
  const mailRoomId =  localStorage.getItem('mailRoomId');


  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [uniqueBranch, setuniqueBranch] = useState([])
  const [uniqueBranchCostCenterDepartmentRoute, setuniqueBranchCostCenterDepartmentRoute] = useState([])
  const [uniqueBranchRoute, setuniqueBranchRoute] = useState([])
  const [uniqueCityZipCode, setuniqueCityZipCode] = useState([])

  const [uniqueDepartment, setuniqueDepartment] = useState([])
  const [uniqueCostCenter, setuniqueCostCenter] = useState([])
  

const [allEmployee , setallEmployee]= useState([]);


interface selectRowType {
  mode: any;
  clickToSelect: boolean; // Fixed typo: clickToSelect instead of clickToSlect
  style: any;
  onSelect: any;
}

const selectRow: selectRowType = {
  mode: "radio",
  clickToSelect: false,
  style: { backgroundColor: '#c8e6c9' },
  onSelect: (row: any) => {
    setSelectedRecords(row.id);
  }
}
  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };

  const pagination = paginationFactory(options);
  const columns: ColumnDescription<selectRowType>[] = [

    { dataField: 'employeeId', text: 'Employee Id' },
    { dataField: 'employeeName', text: ' Name' },
    { dataField: 'emailId', text: 'Email Id' },
    { dataField: 'notifyOnMail', text: 'Notify mail Id' },
    { dataField: 'phoneNumber', text: 'Phone No' },
    { dataField: 'extension', text: 'Extension' },
    { dataField: 'department', text: 'Department' },
    { dataField: 'division', text: 'Division' },
    { dataField: 'routeId', text: 'Route' },
    { dataField: 'floor', text: 'Floor' },
    { dataField: 'costCenter', text: 'Cost Center' },
    { dataField: 'branch', text: 'Branch' },
    { dataField: 'address', text: 'Address' },
    { dataField: 'cityId', text: 'City' },
    { dataField: 'zipcode', text: 'Pincode' },

  ];
  //Table End

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  

  const navigate = useNavigate();
   
  const filteredData = Array.isArray(allEmployee)
  ? allEmployee.filter((selectedRecords) => {
    const values = Object.values(selectedRecords);
    return values.some((value: unknown): value is string =>
      typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }) : [];

  //------------excel import --------------
  
  
     const handleImport = (event: any) => {
    if (!event.target.files) return;
    const files = event.target.files;
    if (files.length) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (event:any) => {
            const wb = read(event.target.result);
            const sheets = wb.SheetNames;
            if (sheets.length) {
              const rows: any  = utils.sheet_to_json(wb.Sheets[sheets[0]]);
              var newArr :any = [...allEmployee, ...rows];
              const awbObjects = newArr.map((data: any, index :any) => (
               {
                eid: index,
                employeeId: (data[Object.keys(data)[0]]).toString(),
                employeeName: data[Object.keys(data)[1]],
                emailId : data[Object.keys(data)[2]],
                notifyOnMail : data[Object.keys(data)[3]],
                phoneNumber : data[Object.keys(data)[4]],
                extension : data[Object.keys(data)[5]],
                department : data[Object.keys(data)[6]],
                division : data[Object.keys(data)[7]],
                floor : data[Object.keys(data)[8]],
                costCenter : data[Object.keys(data)[9]],
                branch : data[Object.keys(data)[10]],
                address : data[Object.keys(data)[11]],
                cityId : data[Object.keys(data)[12]],
                zipcode : data[Object.keys(data)[13]],
                routeId : data[Object.keys(data)[14]],
                mailRoomId : mailRoomId,
                createdBy : createdBy,
                updatedBy : updatedBy ,
             }
             ));
                setallEmployee(awbObjects);
                const uniqueCityPincode :any = [];
                awbObjects.forEach((item: any) => {
                  const { zipcode, cityId  } = item;
                  // Check if the combination of branch, costCenter, and department is not already in the uniqueBranchesAndCostCenters array
                  const isCombinationExistCity = uniqueCityPincode.some((element: any) =>
                    element.zipcode === zipcode && element.cityId === cityId
                  );
                
                  // If the combination is not already in the array, add it
                  if (!isCombinationExistCity) {
                    uniqueCityPincode.push({ zipcode, cityId, createdBy, updatedBy});
                  }
                });
                
                console.log("uniqueBranchesAndCostCenters", uniqueCityPincode);
                setuniqueCityZipCode(uniqueCityPincode)

                const uniqueBranchesAndCostCenters :any = [];
                awbObjects.forEach((item: any) => {
                  const { branch, costCenter, department } = item;
                  // Check if the combination of branch, costCenter, and department is not already in the uniqueBranchesAndCostCenters array
                  const isCombinationExist = uniqueBranchesAndCostCenters.some((element: any) =>
                    element.branch === branch && element.costCenter === costCenter && element.department === department
                  );
                
                  // If the combination is not already in the array, add it
                  if (!isCombinationExist) {
                    uniqueBranchesAndCostCenters.push({ branch, costCenter, department,  mailRoomId });
                  }
                });
                
                console.log("uniqueBranchesAndCostCenters", uniqueBranchesAndCostCenters);
                setuniqueBranchCostCenterDepartmentRoute(uniqueBranchesAndCostCenters)

                const uniqueBrancheRoute :any = [];
                awbObjects.forEach((item: any) => {
                  const { branch,  routeId} = item;
                  // Check if the combination of branch, costCenter, and department is not already in the uniqueBranchesAndCostCenters array
                  const isCombinationExistBR = uniqueBrancheRoute.some((element: any) =>
                    element.branch === branch && element.routeId == routeId
                  );
                
                  // If the combination is not already in the array, add it
                  if (!isCombinationExistBR) {
                    uniqueBrancheRoute.push({ branch,  routeId,  mailRoomId });
                  }
                });
                
                console.log("uniqueBrancheRoute", uniqueBrancheRoute);
                setuniqueBranchRoute(uniqueBrancheRoute)

  
                const uniqueDepartment :any = [];
                awbObjects.forEach((item: any) => {
                  const { branch, department} = item;
                  // Check if the combination of branch, costCenter, and department is not already in the uniqueBranchesAndCostCenters array
                  const isCombinationExistDR = uniqueDepartment.some((element: any) =>
                  element.branch === branch && element.department == department
                  );
                
                  // If the combination is not already in the array, add it
                  if (!isCombinationExistDR) {
                    uniqueDepartment.push({  branch,  department,  mailRoomId });
                  }
                });
                
                console.log("uniqueDepartment", uniqueDepartment);
                setuniqueDepartment(uniqueDepartment)

                const uniqueCostCenter :any = [];
                awbObjects.forEach((item: any) => {
                  const {  costCenter} = item;
                  // const { branch, department, costCenter} = item;
                  // Check if the combination of branch, costCenter, and department is not already in the uniqueBranchesAndCostCenters array
                  // const isCombinationExistCS = uniqueCostCenter.some((element: any) =>
                  // element.branch === branch && element.department == department && element.costCenter == costCenter
                  // );
                   const isCombinationExistCS = uniqueCostCenter.some((element: any) =>
                   element.costCenter == costCenter
                  );
                  // If the combination is not already in the array, add it
                  if (!isCombinationExistCS) {
                    // uniqueCostCenter.push({ branch, department, costCenter,  mailRoomId });
                    uniqueCostCenter.push({ costCenter,  mailRoomId });

                  }
                });
                
                console.log("uniqueCostCenter", uniqueCostCenter);
                setuniqueCostCenter(uniqueCostCenter)
                




const uniqueBranches :any = [];

const uniqueBranchesMap:any = {};

uniqueBranchesAndCostCenters.forEach((item :any) => {
  const { branch } = item;

  // Check if the branch is not already in the uniqueBranchesMap
  if (!uniqueBranchesMap[branch]) {
    uniqueBranchesMap[branch] = true;
    uniqueBranches.push({ branch, mailRoomId });
  }
});

console.log("uniqueBranches", uniqueBranches);
setuniqueBranch(uniqueBranches)

            }
        }
        reader.readAsArrayBuffer(file);

    }
}
  //-------------excel import end here ---------

  const handleSubmit = () => {
    if(allEmployee.length > 0) {
    MasterDataService.importEmployee(allEmployee, uniqueBranchCostCenterDepartmentRoute, uniqueBranch, uniqueBranchRoute, uniqueDepartment, uniqueCostCenter, uniqueCityZipCode).then(
      (data)=>{
        if(data){
          Swal.fire({
            title: "Employee Import in Progress",
            icon: 'success',
            text: "Employee has Imported Please Wait....",
        }).then(function() {
        navigate("../employeelist")
        })  
        }
      }
    )
    }
  }
  return (
    <>
      <MasterHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
          <div className="container col-11">
          <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}> Imported Employee List</p></div>
<div className='col-12 inline'>

        <div className='col-6'>
        <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange= {handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/> <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
    
          {/* <Link className='btn2' to="/employeelist" ><i className='fa fa-list'></i>  Employee List</Link> */}
        </div>
          <div className='col-6' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "end",alignItems:"center" }}>
              <input
                className='searchbar col-3'
                type='text'
                value={searchQuery}
                placeholder='Search Here ...'
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            </div>
            <div className="table-responsive">
            <BootstrapTable rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%' }} 
              keyField='eid'
              striped
              hover
              condensed
              data={filteredData}
              columns={columns}
              pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />
             </div>
          </div>
          <div className='row col-12 d-flex justify-content-center'>
          <Link to="" onClick={handleSubmit} className="btn2 col-2 col-sm-1"  > <i className="fa fa-plus" aria-hidden="true"></i>Add</Link>
          <button className="btn2 col-2 col-sm-1" > <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
          
        </div>
        </div> 
      </div>
    </>
  );
}

export default EmployeeImport