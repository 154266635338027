import React, { useEffect, useState } from 'react'
import MasterHeader from './masterHeader'
import MasterDataMailroomService from '../../services/MasterDataMailroom.service';
import { Link } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import '../inward/inwardloglist.css';
import { useNavigate } from 'react-router-dom';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Swal from 'sweetalert2'
import MasterDataService from '../../services/masterData.service';
import { read, utils } from 'xlsx';



const CostCenterImport = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedRecords, setSelectedRecords] = useState();
  const [allCostCenter, setallCostcenter] = useState([]);
  
  const createdBy =  localStorage.getItem('userId');
  const updatedBy =  localStorage.getItem('userId');
  const mailRoomId =  localStorage.getItem('mailRoomId');
  const branch =(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";


  //Table Start

interface selectRowType {
  mode: any;
  clickToSelect: boolean; // Fixed typo: clickToSelect instead of clickToSlect
  style: any;
  onSelect: any;
}

const selectRow: selectRowType = {
  mode: "radio",
  clickToSelect: true,
  style: { backgroundColor: '#c8e6c9' },
  onSelect: (row: any) => {
    setSelectedRecords(row.id);
  }
}
// ...

  interface Article {
    options: any,
    currSizePerPage: any,
    onSizePerPageChange: any,
  }
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }: Article) => (
    <div className="btn-group" role="group">
      {
        options.map((option: any) => {
          const isSelect = currSizePerPage === `${option.page}`;
          return (
            <button
              key={option.text}
              type="button"
              onClick={() => onSizePerPageChange(option.page)}
              className={`btn ${isSelect ? 'btn-secondary' : 'btn-warning'}`}
            >
              {option.text}
            </button>
          );
        })
      }
    </div>
  );

  interface optionsType {
    sizePerPageRenderer: any
  }
  const options: optionsType = {
    sizePerPageRenderer
  };

  const pagination = paginationFactory(options);
   const columns: ColumnDescription<selectRowType>[] = [
       { dataField: 'costCenter', text: 'Cost Center', align: 'left', headerClasses: 'left-aligned-header'  },   
    { dataField: 'billingDept', text: 'Billing Dept',  align: 'left', headerClasses: 'left-aligned-header' },
  ];

  //Table End
  
  const navigate = useNavigate();
  const filteredData = Array.isArray(allCostCenter)
  ? allCostCenter.filter((selectedRecords) => {
      const values = Object.values(selectedRecords);
      return values.some(
        (value: unknown): value is string =>
          typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
      );
    })
  : [];
  //------------excel import --------------
  
  
  const handleImport = (event: any) => {
    if (!event.target.files) return;
    const files = event.target.files;
    if (files.length) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (event:any) => {
            const wb = read(event.target.result);
            const sheets = wb.SheetNames;
            if (sheets.length) {
              const rows: any  = utils.sheet_to_json(wb.Sheets[sheets[0]]);
              const awbObjects = rows.map((data: any, index :any) => (
               {
                eid: index,
                costCenter: (data[Object.keys(data)[0]]).toString(),
                billingDept: data[Object.keys(data)[1]],
                branch:branch,
                mailRoomId : mailRoomId,
                createdBy : createdBy,
                updatedBy : updatedBy ,
             }
             ));
                setallCostcenter(awbObjects);
            }
        }
        reader.readAsArrayBuffer(file);

    }
}
  //-------------excel import end here ---------

  const handleSubmit = () => {
    if(allCostCenter.length > 0) {
    MasterDataService.importcostCenter(allCostCenter).then(
      (data)=>{
        if(data){
          Swal.fire({
            title: "Cost Center Import in Progress",
            icon: 'success',
            text: "Cost Center Imported Please Wait....",
        }).then(function() {
        navigate("../costcenterlist")
        })  
        }
      }
    )
    }
  }
  
  return (
    <>
      <MasterHeader />
      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
         
          <div className="container col-11">         
          <div className="themeColor" style={{ width: '100%'}} ><p style={{ marginLeft: "10px" }}> Cost Center List </p></div>
          <div className='col-12 inline'>

<div className='col-6'>
<input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange= {handleImport} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/> <label className="custom-file-label" htmlFor="inputGroupFile">Choose file</label>
</div>
            <div className='col-6' style={{ display: "flex", flexDirection: "row", width: "100%", height: "50px", justifyContent: "end",alignItems:"center" }}>
              <input
                className='searchbar col-3'
                type='text'
                value={searchQuery}
                placeholder='Search Here ...'
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            </div>
            <div className="table-responsive">
            <BootstrapTable
              rowStyle={{ fontSize: '15px', padding: '0px', whiteSpace: 'nowrap', width: '100%', textAlign: "left" }}
              keyField='id'
              striped
              hover
              condensed
              data={filteredData}
              columns={columns}
              pagination={pagination}
              selectRow={selectRow}
              filter={filterFactory()}
              filterPosition="top"
            />
</div>
          </div>
        </div>
      </div>
      <div className='btn d-flex justify-content-center'>
        <Link to="" className="btn2 col-2 col-sm-1" onClick={handleSubmit}  > <i className="fa fa-plus" aria-hidden="true"></i>Add</Link>
      
        <button className="btn2 col-2 col-sm-1" type="submit"> <i className="fa fa-sign-out" aria-hidden="true"></i> Close</button>
     </div>

    </>
  );
      }


export default CostCenterImport