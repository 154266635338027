import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import AccountHeader from '../accountHeader'
import VendorHeader from './vendorHeader'
import AccountService from '../../../services/accountData.service'
import Select from 'react-select';
import MasterDataService from '../../../services/masterData.service'
const customStyles = {
    option: (provided: any, { isFocused, isSelected }: any) => ({
      ...provided,
      padding: 5,
      zIndex: 1,
      background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
    }),
    control: (prev: any) => ({
      ...prev,
      padding: 0,
      width: "100%"
  
    }),
    menu: (base: any, { isFocused, isSelected }: any) => ({
      ...base,
      zIndex: 100,
      background: isFocused ? 'hsl(0deg 1.44% 56.86%)' : isSelected ? 'hsl(0deg 0% 20% / 65%)' : undefined,
    }),
  }
const Product = () => {


  const [productName, setproductName] = useState("");
  const [productCode, setproductCode] = useState("");
  const [shipmentmode, setshipmentmode] = useState([]);  
  const [zoneList, setZoneList] = useState([]);  
  const [selectedZones, setselectedZones] = useState<any | [] >([]);
  const [selectedZonesIds, setselectedZonesIds] = useState<string[]>();
  const [modeName, setmodeName] = useState('')
  const [modeId, setmodeId] = useState(0);
  const [serviceProvider, setserviceProvider] = useState('');
  const [serviceProviderId, setserviceProviderId] = useState(0);
  const [serviceProviderList, setserviceProviderList] = useState([]);
  const [cft, setcft] = useState('');
  const { state } = useLocation();
  const navigate = useNavigate();
  const HandleSubmit = () => {
    const zoneIds = selectedZones.map((option :any) => option.value).join(',')
    AccountService.postProduct(productName, serviceProviderId, modeId, zoneIds, cft).then(
      (data) => {
        if (data) {
          navigate('../productlist');
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  }
  // edit the selected data
  const HandleEdit = () => {
    const zoneIds = selectedZones.map((option :any) => option.value).join(',')
    AccountService.putProduct(productName, serviceProviderId, modeId, zoneIds, state, cft).then(
      (data) => {
        if (data) {
          navigate('../productlist');
        }
      }
    ).catch((error) => {
      console.log(error);
    })
  }
  //  get Product by Id
  const getProductById = async () => {
    if (state) {
      try {
        const data = await AccountService.getProductById(state);
        if (data) {
          // console.log("data", data);
          setproductName(data.productName);
          setserviceProviderId(data.serviceProviderId);
          setmodeId(data.shipmentModeId);
          setselectedZonesIds(data.zoneId);
          setcft(data.cftValue)

        } else {
          setproductName('');
          setproductCode('');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

  const HandleDelete = () => {
    if (state !== null)

      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          AccountService.deleteProduct(state).then(
            (data) => {
              if (data.statuscode.message === '200') {
              }
            }
          ).catch((error) => {
            console.log(error);
            console.log("server responding");

          })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Product has been deleted",
          }).then(function () {
            navigate('../productlist');
          })
        } else if (
        
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Product is safe :)",
            }).then(function () {
              navigate('../product');
            }
            )
        }
      })
  }

  const HandleCancel = () => {
    setproductName("");
    setproductCode("");
  }
  useEffect(() => {
    if (state !== null) {
      getProductById();
    }

  }, [])
  // get shipment mode option 
  const getShipmentMode = () => {
    MasterDataService.GetshipmentMode().then(
      async (data) => {
        if (data) {
          const options = data.map((d: any) => ({
            "value": d.id,
            "label": d.modeName
          }))
          await setshipmentmode(options)
          if (state === null) {
            if (data[0]) {
              await setmodeId(data[0]['id'])
              await setmodeName(data[0]['modeName'])
            }
          } 
        }
      }
    );
  }
 
  useEffect(() => {
    getShipmentMode()
}, [])
useEffect(()=>{
  setShipmentMode();
},[modeId, shipmentmode])
const setShipmentMode = () => {
  let modesNames = shipmentmode.find((item: any) => {
    return item['value'] === modeId
  });
  if (modesNames !== undefined) {
    setmodeName(modesNames['label'])
  }
}
  const handleModeChange = (e: any) => {
    setmodeId(e.value);
    setmodeName(e.label)
  }
  const getServiceProvider = () => {
    MasterDataService.GetserviceProvider().then(
      async (data) => {
        if(data) {            
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.serviceProviderName
        }))
        await setserviceProviderList(options)
        if (state === null) {
          if (data[0]) {
            await setserviceProviderId(data[0]['id'])
            await setserviceProvider(data[0]['serviceProviderName'])
          }
        } 
      }
      }
    )
  }
  useEffect(() => {
    getServiceProvider()
}, [])
useEffect(() => {
  setServiceProviderFn()
}, [serviceProviderList, serviceProviderId])

useEffect(() => {
  getZonebyServiceProvider()
}, [serviceProviderId])

const setServiceProviderFn = () =>{
  let serviceProviderLists = serviceProviderList.find((item: any) => {
    return item['value'] === serviceProviderId
  });
  if (serviceProviderLists !== undefined) {
    setserviceProvider(serviceProviderLists['label'])
  }
}
  const handleServiceProvider = (e:any) => {
    setserviceProviderId(e.value);
    setserviceProvider(e.label)
  }

  interface Option {
    value: string;
    label: string;
  }
  
  const findItemsInArray = (array: Option[], values: string[]): Option[] => {
    const foundItems = array.filter((item) => values.includes(item.value));
    return foundItems;
  };

  
  const getZonebyServiceProvider = () => {
    AccountService.getzonebyServiceProvider(serviceProviderId).then(async (data) => {
      if (data) {
        const options = data.map((d:any) => ({
          value: d.id,
          label: d.zoneName,
        }));
        setZoneList(options);
  
        if (state && selectedZonesIds) {
        const selectedItems = findItemsInArray(options, selectedZonesIds);
        setselectedZones(selectedItems)
        }
      }
    });
  };
  const handleZones =  (selected :any) => {
      setselectedZones(selected);
    };

  return (
    <>
      <AccountHeader/>

      <div style={{ margin: "0px" }}>
        <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}></div>
        <div className='container col-11' style={{ paddingLeft: "0px" }} >
          <div className="themeColor width" ><p style={{ marginLeft: "10px" }}>Product Details </p></div>
          <VendorHeader />
          <div className='col-6'>
          <div className='col-12 inline' style={{ marginTop: "10px" }}>
            <label className='col-6'><b>Product Name</b></label>
            <input type='text' className='col-6' value={productName} name='productName' onChange={e => setproductName(e.target.value)} />
          </div>

          <div className='col-12 inline' style={{ marginTop: "10px" }}>
            <label className='col-6'><b>Mode Name</b></label>
            {/* <input type='text' className='col-8' value={productCode} name='productCode' onChange={e => setproductCode(e.target.value)} /> */}
            <div className='col-6' style={{ paddingLeft: '0px', paddingRight : '0px' }}>
            <Select
                        name="modeId"
                        onChange={handleModeChange}
                        options={shipmentmode}
                        styles={customStyles}
                        value={{ value: modeId, label: modeName }}
                      />
                      </div>
          </div>
          <div className='col-12 inline' style={{ marginTop: "10px" }}>
            <label className='col-6'><b>Service Provider</b></label>
            <div className='col-6 select' style={{ paddingLeft: '0px', paddingRight : '0px' }}>
            <Select
                      name="modeId"
                      onChange={handleServiceProvider}
                      options={serviceProviderList}
                      styles={customStyles}
                      value={{ value: serviceProviderId, label: serviceProvider }}
                    />
                    </div>
          </div>
          <div className='col-12 inline' style={{ marginTop: "10px" }}>
            <label className='col-6'><b>CFT</b></label>
            <input type='text' className='col-6' value={cft} name='cft' onChange={e => setcft(e.target.value)} />
          </div>
          <div className='col-12 inline' style={{ marginTop: "10px" }}>
            <label className='col-6'><b>Select Zones</b></label>
            <div className='col-6 select' style={{ paddingLeft: '0px', paddingRight : '0px' }}>
            <Select
                      name="modeId"
                      closeMenuOnSelect={false}
                      onChange={handleZones}
                      options={zoneList}
                      styles={customStyles}
                      isMulti
                      value={selectedZones}
                      // value={{ value: serviceProviderId, label: serviceProvider }}
                    />
                    </div>
          </div>
          </div>
        </div>
        <div className='btn d-flex justify-content-center'>
          {(state === null) ? <Link className="btn2 col-2 col-sm-1"
           onClick={HandleSubmit} to="../productlist"> <i className="fa fa-plus" aria-hidden="true"> </i>  Submit </Link> : <>
            <Link className="btn2 col-2 col-sm-1" onClick={HandleEdit} to="../productlist"> <i className="fa fa-edit"> </i>   Update </Link>
            <button className="btn2 col-2 col-sm-1" onClick={HandleDelete} > <i className="fa fa-trash"> </i>   Delete</button> </>}
          <button className="btn2 col-2 col-sm-1" onClick={HandleCancel}><i className="fa fa-close"></i>   Cancel</button>
          <Link className="btn2 col-2 col-sm-1" to="/productlist"> <i className="fa fa-sign-out" aria-hidden="true"></i>   Close</Link>
        </div>
      </div>
    </>
  )
}

export default Product
