import  { useEffect, useState } from "react";
import '../inward/InwardDelivarySheet.css';
import InwardContainerHeader from "./InwardContainerHeader";
import MasterDataService from "../../services/masterData.service";
import InwardDeliverySheet from "../../services/inwarddeliverysheet.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import Swal from "sweetalert2";
import moment from "moment";

function InwardDelivarySheet() {
const navigate = useNavigate();

const [departmentLabel, setdepartmentLabel] = useState('Select department');
const [department, setdepartment] = useState(0);
const [departmentvalue, setdepartmentvalue] = useState([])
const [routeId, setrouteId] = useState(0);
const [RouteIDvalue, setRouteIDvalue] = useState([]);
const[routeName,setrouteName] = useState('Select Route')
const[routeWiseInwardData,setrouteWiseInwardData] = useState([])
const [selectedRecord, setselectedRecord] = useState([])
const { state } = useLocation();
const [deliveredBy, setdeliveredBy] = useState(0)
const [deliveryByName, setdeliveryByName] = useState('Select Delivery Staff')
const [allDeliveryBoy, setAlldeliveryBoy] = useState([])
const [drsNumber, setdrsNumber] = useState("");

useEffect(()=>{
    if(state){
    getDrsDetails()
    } else {
        getDrsData();
    }
    getDeliveryBoy();
}, [])
// useEffect(()=>{
//     getDeliveryBoy();
// },[deliveredBy])

useEffect(()=>{    
    getRouteId();
},[])
useEffect(()=>{    
    getDepartment();
},[department])
const getDrsDetails = async () => {
    if(state !== null){
   await InwardDeliverySheet.getDrsDetails(state).then(
        (data)=>{
            if(data){
                setrouteWiseInwardData(data.inwardDetails)
                setdeliveredBy(data.drsMaster.deliveredBy)
                setdepartment(data.drsMaster.departmentId)
                setrouteId(data.drsMaster.routeId)
                setdrsNumber(data.drsMaster.drsNumber)
            }
        }
    )
}
}
 //  get department options
 const getDepartment = async () => {
    await MasterDataService.getDepartment().then(
      async (data) => {
        if(data){
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.name
        }))
        options.unshift({ value: 0, label: "Select Department" });
        await setdepartmentvalue(options)
        if(state === null){
        // if (data[0]) {
        //   // setdepartment(0)
        //   // setdepartmentLabel("Select Department")
        // // await setdepartment(data[0]['id'])          
        // // await setdepartmentLabel(data[0]['name'])   
        // }  
      } else {
        let departmentLists = data.find((item: any) => {
          return item.id === department
        });
        if (departmentLists !== undefined) {
          setdepartmentLabel(departmentLists.name)
        }
      }     
      }

      }
    )
  }
const getDeliveryBoy = async () => {
    await MasterDataService.GetdeliveryBoy().then(
        async (data) => {
            if(data) {
                const options = data.map((d: any) => ({
                    "value": d.id,
                    "label": d.deliveryBoyName
                  }))
               await setAlldeliveryBoy(options);
               if(state === null){
                // if (data[0]) {
                //     setdeliveredBy(data[0]['id'])
                //     setdeliveryByName(data[0]['deliveryBoyName'])
                // }
            }else {
                let deliveryBoysList = data.find((item: any) => {
                    return item.id === deliveredBy
                  });
                  if (deliveryBoysList !== undefined) {
                    setdeliveryByName(deliveryBoysList.deliveryBoyName)
                  } 
            }

            }
        }
    )
}
  const getRouteId = async () => {
    await MasterDataService.getRouteId().then(
      async (data) => {
        const options = data.map((d: any) => ({
          "value": d.id,
          "label": d.routeName
        }))
        await setRouteIDvalue(options)
        if(state === null){
        //   if (data[0]) {
        //     // setrouteId(0)
        //     // setrouteName("Select Route")
        //   await setrouteId(data[0]['id'])
        // await setrouteName(data[0]['routeName'])
        //   }
        } else {
            let routesList = data.find((item: any) => {
              return item.id === routeId
            });
            if (routesList !== undefined) {
              setrouteName(routesList.routeName)
            }
          }

      }
    )
  }
useEffect(()=>{
  getDrsData()
},[department, routeId])

  const getDrsData = () => {
    setrouteWiseInwardData([])
    if(state === null){
    InwardDeliverySheet.getInwardData(department, routeId).then(
        (data)=>{
            if(data){
                setrouteWiseInwardData(data)
            }
        }
    )
    }
  }
  interface selectRowType {
    mode: any;
    clickToSelect: boolean; 
    onSelect: any,
    style :any,
    onSelectAll :any
  }
  
  const selectRow: selectRowType = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: (row :never, isSelect :any, rowIndex :any, e:any) => {
        if(isSelect){
            // const rowsValues = {inwardDetailsId : row['id'], tCode : Date.now().toString(), mailRoomId : mailRoomId, branch : branch, createdBy : createdBy}
            // setselectedRecord([...selectedRecord, rowsValues]) ;
            setselectedRecord([...selectedRecord, row['id']])
        }else {
            const secondIndex = selectedRecord.findIndex((Selectrow) => Selectrow['id'] === row['id']);
            selectedRecord.splice(secondIndex, 1);
        }
      },
      onSelectAll: async (isSelect :any, rows :any, e :any) => {
       if(isSelect){
        var IdArray :[] = [];
        rows.map(async (rowDatas:never)=>{
            IdArray.push(rowDatas['id']) ;
        })
        await setselectedRecord(IdArray)
       }else {
       await setselectedRecord([])
       }
      },
    style: (row :any, rowIndex:any) => {
    const backgroundColor = rowIndex >= 0 ? '#00BFFF' : '#00FFFF';
    return { backgroundColor };
    }
  }
  const dateformater = (data: any, row: any) => {
    return  (moment(data)).format('DD/MM/YYYY')
  }
  const timeformater = (data :any, row: any) => {
    if(data.length <= 8) {
      return (data)
    }else {
    const parsedDate = moment(data, "YYYY-MM-DD@HH:mm:ss");
    return (parsedDate.format('hh:mm A'))
    }
  }
  const columns: ColumnDescription<selectRowType>[] = [
    { dataField: 'id', formatter: (cell, row, rowIndex, formatExtraData) => {
      return rowIndex + 1;
    }, text: 'id',  align: 'center'},
    { dataField: 'inwardDate', text: 'Inward Date', sort: true,  formatter: dateformater},
    { dataField: 'inwardTime', text: 'Inward Time' , formatter :timeformater  },
    { dataField: 'batchNo', text: 'Batch Number' },
    { dataField: 'AWB', text: 'AWB  Number' },
    { dataField: 'consignerName', text: 'Consignor', headerAlign: 'left'},
    { dataField: 'employeeName', text: 'Employee', headerAlign: 'left' },
    { dataField: 'inwardQuantity', text: 'No Of Shipment',  align: 'center' },
    { dataField: 'remark', text: 'Remark' },

  ];
  const handleSubmit = () => {    
    const drsDate = (moment(new Date())).format('YYYY-MM-DD')
    if((routeId !== 0 ) && (deliveredBy !== 0) && selectedRecord.length !== 0){
    InwardDeliverySheet.postDrsData(routeId, department, deliveredBy, selectedRecord, drsDate).then(
        (data) =>{
            if(data){
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: "Drs Generated Successfully !",
                showConfirmButton: false,
                timer: 1500,
              }).then(()=>{
                navigate('../inwarddrslist'); 
              });
            }
        }
    ) }
    else {
let message = "";
if((routeId === 0 ) && (deliveredBy === 0) && (selectedRecord.length === 0)){
  message = "Please Select Route , Delivery Staff and Docket Details ! ";
} else if(routeId === 0 ) {
  message = "Please Select Route ! ";
} else if(deliveredBy === 0) {
  message = "Please Select Delivery Staff ! ";
} else {
  message = "Please select atleast One Inward Docket !"
}
      Swal.fire({
        position: 'center',
        icon: 'warning',
        title: message,
        showConfirmButton: false,
        timer: 1500,
      }) 
    }
  }
  
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })
  const handleDelete = () => {
    if (state !== undefined) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          InwardDeliverySheet.deleteDrs(state)
            .catch((error) => {
              console.log(error);
              console.log("server responding");

            })
          Swal.fire({
            title: "Deleted",
            icon: 'success',
            text: "Inward DRS has been deleted",
          }).then(function () {
           navigate('../inwarddrslist')
          })
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          swalWithBootstrapButtons.fire(
            {
              title: "Cancelled",
              icon: 'error',
              text: "Inward DRS is safe :)",
            }).then(function () {
              window.location.reload()
            }
            )
        }
      })
    }
  }
  // const handlePrint =() =>{
  //   navigate("../drsprint", { state })
  // }
  
    return (
        <>
            <InwardContainerHeader />
                <div style={{ margin: "0px" }}>
                <div style={{ display: "flex", flexDirection: "column", paddingTop: "1%" }}>
                    <div className="container " style={{}}>
                    <div className="themeColor width" ><p style={{ marginLeft: "10px" }}> Inward Delivery Run Sheet Entry </p></div>
                    <div className="inline" style={{marginBottom: 10}}>
                                <div className="col-1" style={{padding:0, paddingLeft: 2}}>Route Name  </div>
                                <Select
                                        name="routeId"
                                        onChange={(e:any)=>{setrouteId(e.value); setrouteName(e.label);  }}
                                        options={RouteIDvalue}
                                        value={{ value: routeId, label: routeName }}
                                        className='col-3'
                                        />
                                <div className="col-1">
                                    <label>Department</label>
                                </div>
                                <Select
                                        name="department"
                                        onChange={(e :any)=>{ setdepartment(e.value); setdepartmentLabel(e.label);   }}
                                        options={departmentvalue}
                                        value={{ value: department, label: departmentLabel }}
                                        className='col-3'
                                        />
                            {/* <div className="col-3 top-btn inline">
                            {(state === null) ? <> <button className="btn2 col-4 top-btns" onClick={getDrsData}> Generate</button>
                               </> : ''} 

                            </div>                           */}
                        </div>
                        <div className="width tableContainer">
                        <div className="">
                             <BootstrapTable
                                keyField='id'
                                data={routeWiseInwardData}
                                columns={ columns }
                                selectRow={ selectRow }
                                />
                           
                        </div>
                        </div>
                        <div><hr/></div>
                        <div className="width">
                            <div className="bottom-btn inline">
                               <label> Delivery BY</label>
                            <Select
                                        name="deliveredBy"
                                        onChange={(e:any)=>{setdeliveredBy(e.value); setdeliveryByName(e.label)}}
                                        options={allDeliveryBoy}
                                        value={{ value: deliveredBy, label: deliveryByName }}
                                        className='col-3'
                                        />

                            {(state === null) ? <> <button className="btn2 bottom-btns" onClick={handleSubmit}> Save</button></>
                             : <>  <button className="btn2 bottom-btns" onClick={handleDelete}> Delete</button>
                                {/* <a className="btn2 bottom-btns" target="_blank" to="../drsprint" onClick={handlePrint}> Print </Link> */}
                                <a className="btn2 bottom-btns"  href={(drsNumber !=="") ? process.env.REACT_APP_BASE_UPLOAD_API+"drspdf/"+drsNumber+".pdf" :"#"}
        download="Example-PDF-document"
        target="_blank"
        rel="noreferrer"><i className="fa fa-print"></i> Print</a> </> }
                            </div>
                            </div>
                </div>
                </div>
                </div>
        </>



    );
} export default InwardDelivarySheet;